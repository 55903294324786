import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  dplistTab = true;

  dpgridTab: boolean;
  constructor() { }

  ngOnInit(): void {

  }
  // tslint:disable-next-line:variable-name
  onTab(number) {
    this.dplistTab = false;
    this.dpgridTab = false;
    // tslint:disable-next-line:triple-equals
    if (number == '1') {
      this.dplistTab = true;
      // tslint:disable-next-line:triple-equals
    } else if (number == '2') {
      this.dpgridTab = true;
    }
  }
}
