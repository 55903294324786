import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Users } from '../../_models/appPortal/users';

@Injectable({
  providedIn: 'root'
})
export class FirebaseUserService {
  constructor(private firestore: AngularFirestore) {}

  getUserData(uid: string): Observable<Users> {
    return this.firestore.collection('users').doc<Users>(uid).valueChanges();
  }

  updateUserData(uid: string, userData: Partial<Users>): Promise<void> {
    return this.firestore.collection('users').doc(uid).update(userData);
  }

  countUsers(): Observable<number> {
    return this.firestore.collection('users').get().pipe(
      map(snapshot => snapshot.size)
    );
  }

}
