import {Component, OnDestroy, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AddClientComponent} from '../pdv-clients/_modals/add/addClient.component';
import {ScrollStrategyOptions} from '@angular/cdk/overlay';
import {VendaService} from '../../../../core/services/pdv/venda.service';
import {Vendedor} from '../../../../core/_models/vendedor';
import {VendedorService} from '../../../../core/services/pdv/vendedor.service';
import {SearchClientComponent} from '../pdv-clients/_modals/search/searchClient.component';
import {ProdutoModalComponent} from '../pdv-estoque/_modals/product/produtoModal.component';
import {EstoqueService} from '../../../../core/services/pdv/estoque.service';
import {BloqueioTelaService} from '../../../../core/services/pdv/BloqueioTelaService';
import {Subscription} from 'rxjs';
import {SharedService} from '../../../../core/services/pdv/SharedService';


interface Produto {
  Pedido: string;
  Id_vendedor: string;
  Codigo_filial_origem: string;
  item: number;
  codigo: string;
  referencia: string;
  nome: string;
  cor: string;
  tamanho: string;
  preco: number;
  desconto: number;
  quantidade: number;
  total: number;
  Preco_liquido: number;
}


interface PagamentoPedido {
  Pedido: string;
  Codigo_filial_origem: string;
  Parcela: string;
  CodFormaPgto: string;
  tipo_pgto: string;
  Valor: string;
  Codigo_administradora: string;
  Parcelas_cartao: number;
  valor_moeda: string;
  desconto: number;
  desconto_pgto: number;
}

@Component({
  selector: 'app-appPortal-pedidos',
  templateUrl: './pdv-pedidos.component.html',
  styleUrls: ['./pdv-pedidos.component.scss']
})

export class PdvPedidosComponent implements OnInit, OnDestroy {

  dialogRef: MatDialogRef<any>;
  protected numeroPedido: any;
  protected operacoes: any[];
  protected vendedores: Vendedor[];
  selectedVendedor: any;
  protected selectedOperacao: any;
  SelectedClienteId: any;
  selectedClienteName: any;
  protected listaProdutos: Produto[] = [];
  protected descontoValor: number;
  protected total: any;
  protected descontoPercent: number;
  private senha!: string;
  // tslint:disable-next-line:variable-name
  protected modalSenhaVisivel_val!: boolean;
  // tslint:disable-next-line:variable-name
  protected modalSenhaVisivel_perc!: boolean;
  protected campoDesabilitado = false;
  protected paymentsMethods: any[];
  protected bandeira = 'select';
  protected bandeiraReadonly: boolean;
  private parcelamentoReadonly: boolean;
  protected parcelamento: number;
  private valorPago: number;
  // tslint:disable-next-line:variable-name
  protected selectedClienteCPF_CNPJ: string;
  protected selectedClienteTelephone: string;
  protected formaPamagento = 'select';
  protected licencaExpiradaVisivel: boolean;
  private bloqueioTelaSubscription: Subscription;
  protected pedidoAberto = false;
  protected realizaPagamento = false;
  protected listaProdutosTravada = false;
  private qrCodeImage: string;
  protected pagamentoQrTrue = false;
  // protected Total = 0;
  protected troco = 0;
  // tslint:disable-next-line:variable-name
  protected loading_UpVenda = false;
  protected tiposPgto: any[];
  protected produtoDesconto = 0;
  protected newPedido: {
    Codigo_filial_retirada: string;
    Usuario: string;
    Codigo_cliente: string;
    Operacao_venda: any;
    Pedido: string;
    Vendedor: any;
    Codigo_filial_origem: string;
    Codigo_tab_preco: string;
    Tipo_pedido: string
  };


constructor(public dialog: MatDialog, private scrollStrategyOptions: ScrollStrategyOptions, private vendasService: VendaService,
            private vendedorService: VendedorService, private estoqueService: EstoqueService,
            private bloqueioTelaService: BloqueioTelaService, public sharedService: SharedService) { }

  ngOnInit(): void {
    this.licencaExpiradaVisivel = true;
    this.bloqueioTelaSubscription = this.bloqueioTelaService.getBloqueioTelaObservable().subscribe(bloquear => {
      if (this.licencaExpiradaVisivel && bloquear) {
        this.expiredLicense();
      } else {
        console.log('Licensa válida!');
      }
    });
    this.buscarUltimoIDVenda();
    this.carregarOperacoes();
    const selectedFilial = JSON.parse(sessionStorage.getItem('Filial'));
    this.carregarVendedores(selectedFilial);
    this.recoverSessionStorage();
    this.buscarPaymentsMethods();
    this.buscarTipoPgto();

    this.selectedClienteName = '';
    this.selectedClienteCPF_CNPJ = '';
    this.selectedClienteTelephone = '';
    this.descontoValor = 0;
    this.descontoPercent = 0;
    this.bandeira = 'select';
    this.formaPamagento = 'select';
    this.parcelamento = 1;
    this.valorPago = 0;
    this.total = 0;

    // Limpe a lista de produtos (se você tiver uma variável para isso)
    this.listaProdutos = [];
    this.atualizarListaProdutos();

    // Limpar os campos de input no DOM, verificando a existência de cada elemento
    const inputVendedorID = document.getElementById('inputVendedorID') as HTMLInputElement;
    if (inputVendedorID) {
      inputVendedorID.value = '';
    }

    const inputVendedor = document.getElementById('inputVendedor') as HTMLInputElement;
    if (inputVendedor) {
      inputVendedor.value = '';
    }

    const inputCliente = document.getElementById('inputCliente') as HTMLInputElement;
    if (inputCliente) {
      inputCliente.value = '';
    }

    const inputCpf = document.getElementById('inputCpf') as HTMLInputElement;
    if (inputCpf) {
      inputCpf.value = '';
    }

    const inputTelefone = document.getElementById('inputTelefone') as HTMLInputElement;
    if (inputTelefone) {
      inputTelefone.value = '';
    }

    const descontoValor = document.getElementById('descontoValor') as HTMLInputElement;
    if (descontoValor) {
      descontoValor.value = '0';
    }

    const descontoPercent = document.getElementById('descontoPercent') as HTMLInputElement;
    if (descontoPercent) {
      descontoPercent.value = '0';
    }

    const bandeira = document.getElementById('bandeira') as HTMLSelectElement;
    if (bandeira) {
      bandeira.selectedIndex = 0;
    }

    const formaPagamento = document.getElementById('formaPagamento') as HTMLSelectElement;
    if (formaPagamento) {
      formaPagamento.selectedIndex = 0;
    }

    const parcelamento = document.getElementById('parcelamento') as HTMLSelectElement;
    if (parcelamento) {
      parcelamento.selectedIndex = 0;
    }

    const inputQuantidade = document.getElementById('inputQuantidade') as HTMLInputElement;
    if (inputQuantidade) {
      inputQuantidade.value = '';
    }

    const inputProduto = document.getElementById('inputProduto') as HTMLInputElement;
    if (inputProduto) {
      inputProduto.value = '';
    }

    const codigoProduto = document.getElementById('codigoProduto') as HTMLInputElement;
    if (codigoProduto) {
      codigoProduto.value = '';
    }

    const readOnlyElements = ['descontoPercent', 'descontoValor'];
    readOnlyElements.forEach(id => {
      const element = document.getElementById(id) as HTMLInputElement;
      if (element) {
        element.readOnly = true;
      }
    });

    this.pedidoAberto = false;
    this.listaProdutosTravada = false;
    this.realizaPagamento = false;

  }

  ngOnDestroy(): void {
    this.licencaExpiradaVisivel = false;
    this.bloqueioTelaSubscription.unsubscribe();
  }

  recoverSessionStorage() {
    const selectedOperacao = JSON.parse(sessionStorage.getItem('Op'));
    const selectedVendedor = JSON.parse(sessionStorage.getItem('SVendor'));

    this.selectedOperacao = selectedOperacao;
    this.selectedVendedor = selectedVendedor;

  }

  async produtoNaoEncontrado(): Promise<void> {
    await Swal.fire({
      title: 'Produto não encontrado',
      text: 'Nenhum produto foi encontrado .',
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }

  async expiredLicense(): Promise<void> {
    this.campoDesabilitado = true;
    await Swal.fire({
      title: 'Ops!!',
      text: 'Sua licença expirou, por favor entre em contato para solicitar uma nova.',
      icon: 'error',
      allowOutsideClick: false,
      confirmButtonColor: 'rgb(243, 171, 67)',
      confirmButtonText: 'OK',
      customClass: {
        container: 'swal-custom-container'
      }
    });
  }

  async cancelarPedido(): Promise<void> {
    const result = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você deseja realmente cancelar esse pedido?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'Sim, Cancelar!'
    });

    if (result.isConfirmed) {
      Swal.fire(
        'Cancelado!',
        'Pedido cancelado.',
        'success'
      );
      this.Cancelar();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelado',
        'Operação cancelada, pedido mantido.',
        'error'
      );
    }
  }



  async confirmarExclusao(): Promise<void> {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      Swal.fire(
        'Deleted!',
        'User has been deleted.',
        'success'
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        '',
        'error'
      );
    }
  }

  abrirModalAdd(): void {
    this.dialogRef = this.dialog.open(AddClientComponent, {
      width: '80%',
      maxHeight: '80vh',
      maxWidth: '90vw',
      scrollStrategy: this.scrollStrategyOptions.block()
    });
    // Assinar o evento closeModal emitido pelo AddClientComponent
    this.dialogRef.componentInstance.closeModal.subscribe(() => {
      this.dialogRef.close();
    });
  }

  abrirModalSearch(): void {
    const inputCliente = document.getElementById('inputCliente') as HTMLInputElement;
    const inputClienteValue = inputCliente.value;
    this.dialogRef = this.dialog.open(SearchClientComponent, {
      width: '80%',
      maxHeight: '80vh',
      maxWidth: '90vw',
      scrollStrategy: this.scrollStrategyOptions.block(),
      data: { valorPesquisa: inputClienteValue }
    });
    this.dialogRef.componentInstance.closeModal.subscribe(() => {
      this.dialogRef.close();
    });
  }

  abrirModalProduto(): void {
    const inputProduto = document.getElementById('inputProduto') as HTMLInputElement;
    const inputProdutoValue = inputProduto.value;
    this.dialogRef = this.dialog.open(ProdutoModalComponent, {
      width: '80%',
      maxHeight: '80vh',
      maxWidth: '90vw',
      scrollStrategy: this.scrollStrategyOptions.block(),
      data: { valorPesquisa: inputProdutoValue }
    });
    this.dialogRef.componentInstance.closeModal.subscribe(() => {
      this.dialogRef.close();
    });
  }

  buscarUltimoIDVenda(): void {
    this.vendasService.buscarLastSaleID().subscribe(
      (resposta: any[]) => {
        // Verifica se a resposta não está vazia e se possui o campo "PEDIDO"
        if (resposta && resposta.length > 0 && resposta[0].PEDIDO !== undefined) {
          const ultimoID: number = Math.abs(resposta[0].PEDIDO) + 1;
          // console.log('Último ID de venda:', ultimoID);
          this.numeroPedido = ultimoID.toString(); // Converte para string, já que é um input de texto
        } else {
          console.error('Resposta inválida:', resposta);
        }
      },
      (error: any) => {
        console.error('Erro ao buscar o último ID de venda:', error);
      }
    );
  }

  carregarOperacoes(): void {
    this.vendasService.buscarOperacoesVendas().subscribe(
      operacoes => {
        this.operacoes = operacoes;
        // console.log(this.operacoes);
      },
      error => {
        console.log('Ocorreu um erro ao carregar as operações:', error);
      }
    );
  }

  carregarVendedores(selectedFilial): void {
    let filialFilter: string | null = null;

    if (selectedFilial !== 'all') {
      filialFilter = selectedFilial.toString();
    }

    this.vendedorService.buscarVendorsFilial(selectedFilial).subscribe(
      (vendedoresData: any[]) => {
        // Mapear os dados recebidos para o tipo Vendedor
        this.vendedores = vendedoresData.map((vendedorData: any) => ({
          CODIGO_FILIAL: vendedorData.CODIGO_FILIAL,
          FILIAL: vendedorData.FILIAL,
          VENDEDOR: vendedorData.VENDEDOR,
          NOME_VENDEDOR: vendedorData.NOME_VENDEDOR,
          GERENTE: vendedorData.GERENTE,
          ACESSO_GERENCIAL: vendedorData.ACESSO_GERENCIAL
        }));
        // console.log(this.vendedores);
      },
      error => {
        console.log('Ocorreu um erro ao carregar os vendedores:', error);
      }
    );
  }

  adicionarProduto() {
    const codigoProduto = (document.getElementById('codigoProduto') as HTMLInputElement).value;
    const quantidadeProduto = Number((document.getElementById('inputQuantidade') as HTMLInputElement).value);

    if (codigoProduto && quantidadeProduto > 0) {
      this.estoqueService.buscarPrecoProdutos(codigoProduto).subscribe(
        (response: any) => {
          if (response && response.precoProduto && response.precoProduto.length > 0) {
            const produto = response.precoProduto[0];
            const precoOriginal = produto.PRECO1;
            const desconto = produto.PROMOCAO_DESCONTO !== null && produto.PROMOCAO_DESCONTO !== undefined ? produto.PROMOCAO_DESCONTO : 0;
            const descontoPercentual = desconto / 100;
            const valorDesconto = precoOriginal * descontoPercentual;
            const precoComDesconto = precoOriginal - valorDesconto;
            const totalProduto = precoComDesconto * quantidadeProduto;
            this.produtoDesconto += valorDesconto;

            // tslint:disable-next-line:no-shadowed-variable
            const ultimoItem = this.listaProdutos.length > 0 ? Math.max(...this.listaProdutos.map(produto => produto.item)) : 0;
            const proximoItem = ultimoItem + 1;

            // Função para extrair o código da cor
            function extrairCodigoCor(corString: string) {
              // Divida a string da cor pelo hífen
              const partes = corString.split('-');
              // O código da cor será a primeira parte
              return partes[0];
            }

            const codigoCor = extrairCodigoCor(produto.COR_PRODUTO);

            console.log(codigoCor);

            const produtoAdicionado: Produto = {
              Pedido: '-' + this.numeroPedido,
              Id_vendedor: this.selectedVendedor,
              Codigo_filial_origem: this.sharedService.userFilialID,
              item: proximoItem,
              codigo: produto.CODIGO_BARRA,
              referencia: produto.PRODUTO,
              nome: produto.DESC_PRODUTO,
              cor: codigoCor,
              tamanho: produto.TAMANHO,
              preco: precoOriginal,
              desconto: valorDesconto,
              quantidade: quantidadeProduto,
              total: totalProduto,
              Preco_liquido: precoComDesconto,
            };

            const produtoExistenteIndex = this.listaProdutos.findIndex(item => item.codigo === produtoAdicionado.codigo);

            if (produtoExistenteIndex !== -1) {
              // Se o produto já existe, atualiza a quantidade e o total
              const produtoExistente = this.listaProdutos[produtoExistenteIndex];
              produtoExistente.quantidade += quantidadeProduto;
              produtoExistente.total += totalProduto;
              this.listaProdutos[produtoExistenteIndex] = produtoExistente;
            } else {
              // Se o produto não existe, adiciona à lista
              this.listaProdutos.push(produtoAdicionado);
            }

            // Limpar os campos de input após adicionar o produto à lista
            (document.getElementById('codigoProduto') as HTMLInputElement).value = '';
            (document.getElementById('inputProduto') as HTMLInputElement).value = '';
            (document.getElementById('inputQuantidade') as HTMLInputElement).value = '';

            this.atualizarListaProdutos();
          } else {
            console.log('Produto não encontrado');
          }
        },
        error => {
          console.log('Houve um erro ao buscar as informações do produto');
        }
      );
    }
  }


  atualizarListaProdutos() {
    // Atualizar o campo de subtotal
    const subtotalElement = document.getElementById('subtotal') as HTMLInputElement;
    if (subtotalElement) {
      subtotalElement.value = `R$ ${this.calcularSubtotal().toFixed(2)}`;
    }

    // Atualizar o campo de total
    const totalElement = document.getElementById('total') as HTMLInputElement;
    if (totalElement) {
      this.total = this.calcularTotal(); // Recalcula o total
      totalElement.value = `R$ ${this.total.toFixed(2)}`;
    }

  }

  calcularSubtotal(): number {
    let subtotal = 0;

    this.listaProdutos.forEach(produto => {
      subtotal += produto.preco * produto.quantidade;
    });

    return subtotal;
  }

  calcularTotal(): number {
    let total = 0;

    this.listaProdutos.forEach(produto => {
      // console.log('Produto Total:', produto.total);
      total += Number(produto.total.toFixed(2)); // Arredonda para duas casas decimais antes de adicionar
    });

    // console.log('Desconto Valor:', this.descontoValor || 0);
    total -= this.descontoValor || 0;

    this.total = total.toFixed(2); // Formata o total final com duas casas decimais
    // console.log('Total Final:', Number(total.toFixed(2)));
    return total;
  }


  // Definição da função onCodigoProdutoEnter
  onCodigoProdutoEnter(event: any) {
    const codigoProduto = event.target.value;
    const quantidadeProduto = 1; // Defina a quantidade como 1, você pode ajustar conforme necessário

    if (codigoProduto) {
      this.estoqueService.buscarPrecoProdutos(codigoProduto).subscribe(
        (response: any) => {
          if (response && response.precoProduto) {
            const produto = Array.isArray(response.precoProduto) ? response.precoProduto[0] : response.precoProduto;
            this.inserirProdutoNaTabela(produto, quantidadeProduto);
            (document.getElementById('codigoProduto') as HTMLInputElement).value = '';
          } else {
            console.log('Produto não encontrado');
          }
        },
        (error) => {
          console.log('Houve um erro ao buscar as informações do produto');
        }
      );
    }
  }


  inserirProdutoNaTabela(produto: any, quantidade: number) {
    if (produto) {
      const produtoExistente = this.listaProdutos.find((item) => item.codigo === produto.CODIGO_BARRA);

      if (produtoExistente) {
        produtoExistente.quantidade += quantidade;
        const precoOriginal = produto.PRECO1;
        const desconto = produto.PROMOCAO_DESCONTO !== null && produto.PROMOCAO_DESCONTO !== undefined ? produto.PROMOCAO_DESCONTO : 0;
        const descontoPercentual = desconto / 100;
        const valorDesconto = precoOriginal * descontoPercentual;
        const precoComDesconto = precoOriginal - valorDesconto;
        produtoExistente.total = Number((precoComDesconto * produtoExistente.quantidade).toFixed(2));

        this.produtoDesconto += valorDesconto;
      } else {
        const desconto = produto.PROMOCAO_DESCONTO !== null && produto.PROMOCAO_DESCONTO !== undefined ? produto.PROMOCAO_DESCONTO : 0;
        const precoOriginal = produto.PRECO1;
        const descontoPercentual = desconto / 100;
        const valorDesconto = precoOriginal * descontoPercentual;
        const precoComDesconto = precoOriginal - valorDesconto;
        const totalProduto = precoComDesconto * quantidade;

        this.produtoDesconto += valorDesconto;

        // Verifica o último item na lista de produtos
        // tslint:disable-next-line:no-shadowed-variable
        const ultimoItem = this.listaProdutos.length > 0 ? Math.max(...this.listaProdutos.map(produto => produto.item)) : 0;

        // Calcula o próximo número de item
        const proximoItem = ultimoItem + 1;

        // Função para extrair o código da cor
        function extrairCodigoCor(corString: string) {
          // Divida a string da cor pelo hífen
          const partes = corString.split('-');
          // O código da cor será a primeira parte
          return partes[0];
        }

        const codigoCor = extrairCodigoCor(produto.COR_PRODUTO);

        const produtoAdicionado: Produto = {
          Pedido: '-' + this.numeroPedido,
          Id_vendedor: this.selectedVendedor,
          Codigo_filial_origem: this.sharedService.userFilialID,
          item: proximoItem,
          codigo: produto.CODIGO_BARRA,
          referencia: produto.PRODUTO,
          nome: produto.DESC_PRODUTO,
          cor: codigoCor,
          tamanho: produto.TAMANHO,
          preco: precoOriginal,
          desconto: valorDesconto,
          quantidade,
          total: totalProduto,
          Preco_liquido: precoComDesconto,
        };

        console.log(produtoAdicionado);

        this.listaProdutos.push(produtoAdicionado);
      }

      // Atualiza a lista de produtos no HTML, se a função estiver definida e implementada corretamente
      this.atualizarListaProdutos();
    }
  }


  async confirmarLimparLista(): Promise<void> {
    const result = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Deseja limpar a lista de produtos?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'Sim, limpar!'
    });

    if (result.isConfirmed) {
      this.listaProdutos = []; // Limpa a lista de produtos
      this.atualizarListaProdutos(); // Atualiza a lista de produtos no HTML
      Swal.fire(
        'Limpo!',
        'A lista foi limpa.',
        'success'
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelado',
        '',
        'error'
      );
    }
  }

  removerProduto(codigo: string) {
    const produtoIndex = this.listaProdutos.findIndex(produto => produto.codigo === codigo);
    if (produtoIndex !== -1) {
      if (this.listaProdutos[produtoIndex].quantidade > 1) {
        this.listaProdutos[produtoIndex].quantidade--;
        // tslint:disable-next-line:max-line-length
        this.listaProdutos[produtoIndex].total = (this.listaProdutos[produtoIndex].preco - (this.listaProdutos[produtoIndex].preco * this.listaProdutos[produtoIndex].desconto / 100)) * this.listaProdutos[produtoIndex].quantidade;
      } else {
        this.listaProdutos.splice(produtoIndex, 1);
      }
      this.atualizarListaProdutos();
    }
  }


  // Função para adicionar mais um à quantidade de um produto na lista
  adicionarQuantidade(codigo: string) {
    // tslint:disable-next-line:no-shadowed-variable
    const produto = this.listaProdutos.find(produto => produto.codigo === codigo);
    if (produto) {
      produto.quantidade++;
      produto.total = (produto.preco - (produto.preco * produto.desconto / 100)) * produto.quantidade;
      this.atualizarListaProdutos();
    }
  }

  // Atualiza o desconto em valor com base no desconto em percentual
  atualizarDescontoPercent() {
    const subtotal = this.calcularSubtotal();

    this.descontoValor = +(subtotal * (this.descontoPercent / 100)).toFixed(2);
    this.descontoValor = Math.min(this.descontoValor, subtotal); // Garante que o desconto em R$ não ultrapasse o subtotal

    this.atualizarListaProdutos(); // Atualiza os campos de subtotal e total
  }

  // Atualiza o desconto em percentual com base no desconto em valor
  atualizarDescontoValor() {
    const subtotal = this.calcularSubtotal();

    this.descontoPercent = +(this.descontoValor / subtotal * 100).toFixed(2);
    this.descontoPercent = Math.min(this.descontoPercent, 100); // Garante que o desconto em % não ultrapasse 100%

    this.atualizarListaProdutos(); // Atualiza os campos de subtotal e total
  }

  abrirModalSenha_valor() {
    // Abre o modal de senha
    this.modalSenhaVisivel_val = true;
  }

  abrirModalSenha_percent() {
    // Abre o modal de senha
    this.modalSenhaVisivel_perc = true;
  }

  fecharModalSenha() {
    // Fecha o modal de senha e limpa o campo de senha
    this.modalSenhaVisivel_val = false;
    this.modalSenhaVisivel_perc = false;
    this.senha = '';
  }

  buscarPaymentsMethods(): void {
    this.vendasService.buscarPaymentsMethods().subscribe(
      (data) => {
        this.paymentsMethods = data;
      },
      (error) => {
        console.error('Erro ao buscar métodos de pagamento:', error);
      }
    );
  }

  buscarTipoPgto(): void {
    this.vendasService.buscarTipoPgto().subscribe(
      (data) => {
        this.tiposPgto = data;
      },
      (error) => {
        console.error('Erro ao buscar tipos de pagamento:', error);
      }
    );
  }


  onFormaPagamentoDinheiro() {
    const formaPagamento = (document.getElementById('formaPagamento') as HTMLSelectElement).value;

    if (formaPagamento === '02') {
      this.bandeiraReadonly = true;
      this.parcelamentoReadonly = true;
      this.bandeira = 'AV';
      this.parcelamento = 1;
    } else if (formaPagamento === 'qr-flx') {
      this.bandeira = 'PX';
      this.bandeiraReadonly = true;
      this.parcelamentoReadonly = true;
    } else {
      this.bandeiraReadonly = false;
      this.parcelamentoReadonly = false;
      this.bandeira = 'select';
    }
  }

  Cancelar() {
    const dadosPedido = {
      Pedido: this.newPedido.Pedido,
      Codigo_filial_origem: this.newPedido.Codigo_filial_origem
    };
    // Insere o pagamento
    this.vendasService.cancelarPedido(dadosPedido).subscribe(
      (response) => {
        // Redefina os valores das variáveis para limpar os campos
        this.selectedClienteName = '';
        this.selectedClienteCPF_CNPJ = '';
        this.selectedClienteTelephone = '';
        this.descontoValor = 0;
        this.descontoPercent = 0;
        this.bandeira = 'select';
        this.formaPamagento = 'select';
        this.parcelamento = 1;
        this.valorPago = 0;
        this.total = 0;

        // Limpe a lista de produtos (se você tiver uma variável para isso)
        this.listaProdutos = [];
        this.atualizarListaProdutos();

        // Limpar os campos de input no DOM, verificando a existência de cada elemento
        const inputVendedorID = document.getElementById('inputVendedorID') as HTMLInputElement;
        if (inputVendedorID) {
          inputVendedorID.value = '';
        }

        const inputVendedor = document.getElementById('inputVendedor') as HTMLInputElement;
        if (inputVendedor) {
          inputVendedor.value = '';
        }

        const inputCliente = document.getElementById('inputCliente') as HTMLInputElement;
        if (inputCliente) {
          inputCliente.value = '';
        }

        const inputCpf = document.getElementById('inputCpf') as HTMLInputElement;
        if (inputCpf) {
          inputCpf.value = '';
        }

        const inputTelefone = document.getElementById('inputTelefone') as HTMLInputElement;
        if (inputTelefone) {
          inputTelefone.value = '';
        }

        const descontoValor = document.getElementById('descontoValor') as HTMLInputElement;
        if (descontoValor) {
          descontoValor.value = '0';
        }

        const descontoPercent = document.getElementById('descontoPercent') as HTMLInputElement;
        if (descontoPercent) {
          descontoPercent.value = '0';
        }

        const bandeira = document.getElementById('bandeira') as HTMLSelectElement;
        if (bandeira) {
          bandeira.selectedIndex = 0;
        }

        const formaPagamento = document.getElementById('formaPagamento') as HTMLSelectElement;
        if (formaPagamento) {
          formaPagamento.selectedIndex = 0;
        }

        const parcelamento = document.getElementById('parcelamento') as HTMLSelectElement;
        if (parcelamento) {
          parcelamento.selectedIndex = 0;
        }

        const inputQuantidade = document.getElementById('inputQuantidade') as HTMLInputElement;
        if (inputQuantidade) {
          inputQuantidade.value = '';
        }

        const inputProduto = document.getElementById('inputProduto') as HTMLInputElement;
        if (inputProduto) {
          inputProduto.value = '';
        }

        const codigoProduto = document.getElementById('codigoProduto') as HTMLInputElement;
        if (codigoProduto) {
          codigoProduto.value = '';
        }

        const readOnlyElements = ['descontoPercent', 'descontoValor'];
        readOnlyElements.forEach(id => {
          const element = document.getElementById(id) as HTMLInputElement;
          if (element) {
            element.readOnly = true;
          }
        });

        this.pedidoAberto = false;
        this.listaProdutosTravada = false;
        this.realizaPagamento = false;
      }, (error) => {
      console.error('Erro ao cancelar pedido:', error);
    });
  }

  calcularTroco(): number {
    this.total = parseFloat((document.getElementById('total') as HTMLSelectElement).value.replace(/[^0-9.-]/g, ''));
    if (this.valorPago >= this.total) {
      this.troco = this.valorPago - this.total;
    } else {
      this.troco = 0; // Caso o valor pago seja menor que o total da compra, o troco será zero
    }
    return this.troco;
  }

  checkValorPago(): void {
    const formaPagamento = (document.getElementById('formaPagamento') as HTMLSelectElement).value;
    if (formaPagamento === 'Dinheiro') {
      if (this.valorPago === null || isNaN(this.valorPago)) {
        this.valorPago = 0;
      }
    }
  }

  async IdClienteNaoEncontrado(campo: string): Promise<void> {
    await Swal.fire({
      title: 'Ops!',
      text: `Não foi possível encontrar ${campo}.`,
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }

  async listaProdutoZerado(): Promise<void> {
    await Swal.fire({
      title: 'Ops!',
      text: `A lista deve conter ao menos um produto.`,
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }

  abrirPedido() {
    const Pedido = '-' + this.numeroPedido;
    const selectedCliente = this.sharedService.selectedCliente;
    if (!selectedCliente || !selectedCliente.CODIGO_CLIENTE) {
      const campo = 'o Cliente';
      return this.IdClienteNaoEncontrado(campo);
    } else if (!this.selectedVendedor ) {
      const campo = 'o Vendedor';
      return this.IdClienteNaoEncontrado(campo);
    } else if (!this.selectedOperacao) {
      const campo = 'a Operacao';
      return this.IdClienteNaoEncontrado(campo);
    }
    // tslint:disable-next-line:variable-name
    const Codigo_cliente = selectedCliente.CODIGO_CLIENTE;
    // tslint:disable-next-line:variable-name
    const Codigo_filial_origem = this.sharedService.userFilialID;
    // tslint:disable-next-line:variable-name
    const Codigo_filial_retirada = this.sharedService.userFilialID;
    // tslint:disable-next-line:variable-name
    const Tipo_pedido = '3';
    // tslint:disable-next-line:variable-name no-shadowed-variable
    const Vendedor = this.selectedVendedor;
    // tslint:disable-next-line:variable-name
    const Operacao_venda = this.selectedOperacao;
    // tslint:disable-next-line:variable-name
    const Codigo_tab_preco = this.sharedService.tabPreco;
    // tslint:disable-next-line:variable-name
    const Usuario = this.sharedService.currentUser.toLowerCase().trim();

    // tslint:disable-next-line:no-shadowed-variable
    const newPedido = {
      Pedido,
      Codigo_cliente,
      Codigo_filial_origem,
      Codigo_filial_retirada,
      Tipo_pedido,
      Vendedor,
      Operacao_venda,
      Codigo_tab_preco,
      Usuario
    };

    this.newPedido = newPedido;

    // Inicia o pedido
    this.vendasService.abrirPedido(newPedido).subscribe(
      (response) => {
        console.log('Pedido iniciado com sucesso.');
        this.campoDesabilitado = false;
        this.pedidoAberto = true;
      },
      (error) => {
        console.error('Erro ao iniciar o pedido:', error);
        // Aqui você pode lidar com o erro da inserção dos produtos, se necessário
      }
    );
  }

  iniciarPagamento() {
    if (this.listaProdutos.length === 0) {
      return this.listaProdutoZerado();
    } else {
      // Insere os produtos
      this.vendasService.inserirProdutos(this.listaProdutos).subscribe(
        (response) => {
          console.log('Produtos inseridos com sucesso:', response);
          this.realizaPagamento = true;
          this.listaProdutosTravada = true;
        },
        (error) => {
          console.error('Erro ao inserir produtos:', error);
        }
      );
    }
  }


  async flexDuckQrConfirm(): Promise<void> {
    // Verifica se o nome do destinatário excede 25 caracteres
    // tslint:disable-next-line:max-line-length
    const nomeDestinatario = this.sharedService.cnpjName.length > 25 ? this.sharedService.cnpjName.substring(0, 25) : this.sharedService.cnpjName;

    const dadosQrCodeFlexDuck = {
      nomeDestinatario,
      chaveDestinatario: this.sharedService.chavePix,
      valor: this.total,
      cidadeRemetente: this.sharedService.cidadePix,
      descricao: 'Pagamento via PIX FlexDuckPdv',
      idTransacao: this.numeroPedido
    };

    console.log(dadosQrCodeFlexDuck);

    try {
      // Chama o método para gerar o QR Code no backend e recebe a imagem como Blob
      const qrCodeBlob: Blob = await this.vendasService.geraQrCodeFlexDuck(dadosQrCodeFlexDuck).toPromise();

      // Converte o Blob em uma URL de dados
      const qrCodeImageUrl = URL.createObjectURL(qrCodeBlob);

      const result = await Swal.fire({
        title: 'QR FlexDuck',
        // tslint:disable-next-line:max-line-length
        html: `<div><img src="${qrCodeImageUrl}" alt="QR Code"></div><div>${this.sharedService.cnpjName}</div>
               <div>R$ ${this.total.toFixed(2)}</div>`,
        icon: 'success',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#ff9700',
        confirmButtonText: 'Continuar'
      });

      if (result.isConfirmed) {
        this.pagamentoQrTrue = true;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelado',
          '',
          'error'
        );
      }
    } catch (error) {
      console.error('Error generating QR code:', error);
      Swal.fire(
        'Error',
        'Failed to generate QR code',
        'error'
      );
    }
  }


  finalizarVenda() {
    const pedido = '-' + this.numeroPedido;
    const parcelamento = this.parcelamento;
    const codigoAdmInput = document.getElementById('bandeira') as HTMLInputElement;
    const codigoAdm = codigoAdmInput.value;
    const tipoPagamentoSelected = document.getElementById('tipoPagamento') as HTMLInputElement;
    const tipoPagamento = tipoPagamentoSelected.value;
    const formaPagamento = this.formaPamagento;

    // Verificar se os valores são válidos
    if (pedido && parcelamento && codigoAdm && formaPagamento) {

      const pagamento: PagamentoPedido = {
        Pedido: pedido,
        Codigo_filial_origem: this.sharedService.userFilialID,
        Parcela: '01',
        CodFormaPgto: formaPagamento,
        tipo_pgto: tipoPagamento,
        Valor: this.total,
        Codigo_administradora: codigoAdm,
        Parcelas_cartao: parcelamento,
        valor_moeda: this.total,
        desconto: this.produtoDesconto,
        desconto_pgto: this.descontoValor
      };
      console.log(pagamento);
      // Insere o pagamento
      this.vendasService.inserirPagamento(pagamento).subscribe(
        (response) => {
          console.log('Pagamento inserido com sucesso:', response);
          this.vendaRegistrada();
        },
        (error) => {
          console.error('Erro ao inserir pagamento:', error);
        }
      );
      return null;
    } else {
      console.error('Por favor, preencha todos os campos necessários.');
      // this.mostrarMensagemErro('Por favor, preencha todos os campos necessários.');
    }
  }

  async vendaRegistrada(): Promise<void> {
    await Swal.fire({
      title: 'Venda Registrada',
      icon: 'success',
      confirmButtonColor: 'rgb(255,151,0)',
      confirmButtonText: 'OK'
    });
    // Redefina os valores das variáveis para limpar os campos
    this.selectedClienteName = '';
    this.selectedClienteCPF_CNPJ = '';
    this.selectedClienteTelephone = '';
    this.descontoValor = 0;
    this.descontoPercent = 0;
    this.bandeira = 'select';
    this.formaPamagento = 'select';
    this.parcelamento = 1;
    this.valorPago = 0;
    this.total = 0;

    // Limpe a lista de produtos (se você tiver uma variável para isso)
    this.listaProdutos = [];
    this.atualizarListaProdutos();
    this.buscarUltimoIDVenda();

    // Limpar os campos de input no DOM, verificando a existência de cada elemento
    const inputVendedorID = document.getElementById('inputVendedorID') as HTMLInputElement;
    if (inputVendedorID) { inputVendedorID.value = ''; }

    const inputVendedor = document.getElementById('inputVendedor') as HTMLInputElement;
    if (inputVendedor) { inputVendedor.value = ''; }

    const inputCliente = document.getElementById('inputCliente') as HTMLInputElement;
    if (inputCliente) { inputCliente.value = ''; }

    const inputCpf = document.getElementById('inputCpf') as HTMLInputElement;
    if (inputCpf) { inputCpf.value = ''; }

    const inputTelefone = document.getElementById('inputTelefone') as HTMLInputElement;
    if (inputTelefone) { inputTelefone.value = ''; }

    const descontoValor = document.getElementById('descontoValor') as HTMLInputElement;
    if (descontoValor) { descontoValor.value = '0'; }

    const descontoPercent = document.getElementById('descontoPercent') as HTMLInputElement;
    if (descontoPercent) { descontoPercent.value = '0'; }

    const bandeira = document.getElementById('bandeira') as HTMLSelectElement;
    if (bandeira) { bandeira.selectedIndex = 0; }

    const formaPagamento = document.getElementById('formaPagamento') as HTMLSelectElement;
    if (formaPagamento) { formaPagamento.selectedIndex = 0; }

    const parcelamento = document.getElementById('parcelamento') as HTMLSelectElement;
    if (parcelamento) { parcelamento.selectedIndex = 0; }

    const inputQuantidade = document.getElementById('inputQuantidade') as HTMLInputElement;
    if (inputQuantidade) { inputQuantidade.value = ''; }

    const inputProduto = document.getElementById('inputProduto') as HTMLInputElement;
    if (inputProduto) { inputProduto.value = ''; }

    const codigoProduto = document.getElementById('codigoProduto') as HTMLInputElement;
    if (codigoProduto) { codigoProduto.value = ''; }

    const readOnlyElements = ['descontoPercent', 'descontoValor'];
    readOnlyElements.forEach(id => {
      const element = document.getElementById(id) as HTMLInputElement;
      if (element) { element.readOnly = true; }
    });

    this.pedidoAberto = false;
    this.listaProdutosTravada = false;
    this.realizaPagamento = false;
  }

  selectTroca(codigoProduto: string) {
    // Encontre o índice do produto na lista de produtos pelo código
    const index = this.listaProdutos.findIndex(p => p.codigo === codigoProduto);

    if (index !== -1) {
      // Verifique se o total do produto é negativo
      if (this.listaProdutos[index].total < 0) {
        // Se for negativo, torne-o positivo multiplicando por -1
        this.listaProdutos[index].total *= -1;
        this.listaProdutos[index].quantidade = 1;
      } else {
        // Caso contrário, torne-o negativo multiplicando por -1
        this.listaProdutos[index].total *= -1;
        this.listaProdutos[index].quantidade = -1;
      }

      // Atualize a lista de produtos para refletir as alterações na interface do usuário
      this.atualizarListaProdutos();
    }
  }


}
