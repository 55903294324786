import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatComponent } from './chat/chat.component';
import { FormElementComponent } from './form-element/form-element.component';
import { TablesComponent } from './UI-ELEMENTS/tables/tables.component';
import { ChartsComponent } from './charts/charts.component';
import { WidgetsComponent } from './widgets/widgets.component';
import { CalenderComponent } from './calender/calender.component';
import { FilemanagerComponent } from './filemanager/filemanager.component';
import { ContactsComponent } from './contacts/contacts.component';
import { SettingsComponent } from './settings/settings.component';
import { MapsComponent } from './maps/maps.component';
import { ProfileComponent } from './profile/profile.component';
import { SearchComponent } from './search/search.component';
import { PrDashboardComponent } from './project/pr-dashboard/pr-dashboard.component';
import { PrProjectlistComponent } from './project/pr-projectlist/pr-projectlist.component';
import { PrTaskboardComponent } from './project/pr-taskboard/pr-taskboard.component';
import { PrTicketlistComponent } from './project/pr-ticketlist/pr-ticketlist.component';
import { PrTicketdetailsComponent } from './project/pr-ticketdetails/pr-ticketdetails.component';
import { PrClientsComponent } from './project/pr-clients/pr-clients.component';
import { PrTodolistComponent } from './project/pr-todolist/pr-todolist.component';
import { ProjectComponent } from './project/project.component';
import { IconFontawesomeComponent } from './UI-ELEMENTS/icons/icon-fontawesome/icon-fontawesome.component';
import { IconFeatherComponent } from './UI-ELEMENTS/icons/icon-feather/icon-feather.component';
import { IconLinesComponent } from './UI-ELEMENTS/icons/icon-lines/icon-lines.component';
import { IconFlagsComponent } from './UI-ELEMENTS/icons/icon-flags/icon-flags.component';
import { IconPaymentsComponent } from './UI-ELEMENTS/icons/icon-payments/icon-payments.component';
import { GalleryComponent } from './ui-elements/gallery/gallery.component';
import { WCardComponent } from './widgets/w-card/w-card.component';
import { WStaticsComponent } from './widgets/w-statics/w-statics.component';
import { WDataComponent } from './widgets/w-data/w-data.component';
import { WSocialComponent } from './widgets/w-social/w-social.component';
import { WOtherComponent } from './widgets/w-other/w-other.component';
import { PdvComponent } from './pdv/pdv.component';
import { PdvDashboardComponent } from './pdv/pdv-dashboard/pdv-dashboard.component';
import { ErpReportComponent } from './pdv/pdv-report/erp-report.component';
import { ErpAccountsComponent } from './pdv/pdv-accounts/erp-accounts.component';
import { ErpPayrollComponent } from './pdv/pdv-payroll/erp-payroll.component';
import { ErpEventsComponent } from './pdv/pdv-events/erp-events.component';
import { ErpHolidaysComponent } from './pdv/pdv-holidays/erp-holidays.component';
import { ErpActivitiesComponent } from './pdv/pdv-activities/erp-activities.component';
import { PdvEstoqueComponent } from './pdv/pdv-estoque/pdv-estoque.component';
import { ErpDepartmentsComponent } from './pdv/pdv-departments/erp-departments.component';
import { ErpEmployeeComponent } from './pdv/pdv-employee/erp-employee.component';
import { PdvClientsComponent } from './pdv/pdv-clients/pdv-clients.component';
import { PdvPrecoComponent } from './pdv/pdv-preco/pdv-preco.component';
import { PdvPedidosComponent } from './pdv/pdv-pedidos/pdv-pedidos.component';
import { UserTypeGuard } from '../../core/services/user-type.guard';
import { AuthGuard } from '../../core/_helpers';
import { AppPortalComponent } from './appPortal/appPortal.component';
import { AppPortalClientsComponent } from './appPortal/appPortal-clients/appPortal-clients.component';
import { AppPortalDashboardComponent } from './appPortal/appPortal-dashboard/appPortal-dashboard.component';
import { AppPortalEstoqueComponent } from './appPortal/appPortal-estoque/appPortal-estoque.component';
import { AppPortalPrecoComponent } from './appPortal/appPortal-preco/appPortal-preco.component';
import { AppPortalPedidosComponent } from './appPortal/appPortal-pedidos/appPortal-pedidos.component';
import {AppPortalReportComponent} from './appPortal/appPortal-report/appPortal-report.component';

const routes: Routes = [
  {
    path: 'appPortal',
    component: AppPortalComponent,
    data: { title: ':: FlexDuck :: Home' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-dashboard',
    component: AppPortalDashboardComponent,
    data: { title: ':: FlexDuck :: Home' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-produtos',
    component: AppPortalEstoqueComponent,
    data: { title: ':: FlexDuck :: Produtos' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-cupons',
    component: AppPortalPrecoComponent,
    data: { title: ':: FlexDuck :: Cupons' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-pedidos',
    component: AppPortalPedidosComponent,
    data: { title: ':: FlexDuck :: Pedidos' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-clientes',
    component: AppPortalClientsComponent,
    data: { title: ':: FlexDuck :: Clientes' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-departments',
    component: ErpDepartmentsComponent,
    data: { title: ':: FlexDuck :: HR Departments' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-employee',
    component: ErpEmployeeComponent,
    data: { title: ':: FlexDuck :: HR Employee' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-activities',
    component: ErpActivitiesComponent,
    data: { title: ':: FlexDuck :: HR Activities' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-holidays',
    component: ErpHolidaysComponent,
    data: { title: ':: FlexDuck :: HR Holidays' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-events',
    component: ErpEventsComponent,
    data: { title: ':: FlexDuck :: HR Events' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-payroll',
    component: ErpPayrollComponent,
    data: { title: ':: FlexDuck :: HR Payroll' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-accounts',
    component: ErpAccountsComponent,
    data: { title: ':: FlexDuck :: HR Accounts' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'appPortal-reports',
    component: AppPortalReportComponent,
    data: { title: ':: FlexDuck :: HR Report' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv',
    component: PdvComponent,
    data: { title: ':: FlexDuck :: Home' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-dashboard',
    component: PdvDashboardComponent,
    data: { title: ':: FlexDuck :: Home' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-estoque',
    component: PdvEstoqueComponent,
    data: { title: ':: FlexDuck :: Estoque' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-preco',
    component: PdvPrecoComponent,
    data: { title: ':: FlexDuck :: Preço' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-pedidos',
    component: PdvPedidosComponent,
    data: { title: ':: FlexDuck :: Pedidos' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-clients',
    component: PdvClientsComponent,
    data: { title: ':: FlexDuck :: Clients' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-departments',
    component: ErpDepartmentsComponent,
    data: { title: ':: FlexDuck :: HR Departments' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-employee',
    component: ErpEmployeeComponent,
    data: { title: ':: FlexDuck :: HR Employee' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-activities',
    component: ErpActivitiesComponent,
    data: { title: ':: FlexDuck :: HR Activities' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-holidays',
    component: ErpHolidaysComponent,
    data: { title: ':: FlexDuck :: HR Holidays' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-events',
    component: ErpEventsComponent,
    data: { title: ':: FlexDuck :: HR Events' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-payroll',
    component: ErpPayrollComponent,
    data: { title: ':: FlexDuck :: HR Payroll' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-accounts',
    component: ErpAccountsComponent,
    data: { title: ':: FlexDuck :: HR Accounts' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'pdv-reports',
    component: ErpReportComponent,
    data: { title: ':: FlexDuck :: HR Report' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'search',
    component: SearchComponent,
    data: { title: ':: FlexDuck :: Search' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'calender',
    component: CalenderComponent,
    data: { title: ':: FlexDuck :: Calendar' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    data: { title: ':: FlexDuck :: Contacts' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'chat',
    component: ChatComponent,
    data: { title: ':: FlexDuck ::  Chat' },
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'filemanager',
    component: FilemanagerComponent,
    data: { title: ':: FlexDuck ::  Filemanager'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'project',
    component: ProjectComponent,
    data: { title: ':: FlexDuck :: Project'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'project-deashboard',
    component: PrDashboardComponent,
    data: { title: ':: FlexDuck :: Project Dashboard'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'project-list',
    component: PrProjectlistComponent,
    data: { title: ':: FlexDuck :: Project List'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'project-taskboard',
    component: PrTaskboardComponent,
    data: { title: ':: FlexDuck :: Project Taskboard'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'project-ticketlist',
    component: PrTicketlistComponent,
    data: { title: ':: FlexDuck :: Project Ticketlist'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'project-ticketdetails',
    component: PrTicketdetailsComponent,
    data: { title: ':: FlexDuck :: Project Ticketdetails'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'project-clients',
    component: PrClientsComponent,
    data: { title: ':: FlexDuck :: Project Clients'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'project-todo-list',
    component: PrTodolistComponent,
    data: { title: ':: FlexDuck :: Project Todolist'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'icon-fontawesome',
    component: IconFontawesomeComponent,
    data: { title: ':: FlexDuck :: Icon Fontawesome'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'icon-feather',
    component: IconFeatherComponent,
    data: { title: ':: FlexDuck :: Icon Feather'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'icon-lines',
    component: IconLinesComponent,
    data: { title: ':: FlexDuck :: Icon Lines'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'icon-flags',
    component: IconFlagsComponent,
    data: { title: ':: FlexDuck :: Icon Flags'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'icon-payments',
    component: IconPaymentsComponent,
    data: { title: ':: FlexDuck :: Icon Payments'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'table',
    component: TablesComponent,
    data: { title: ':: FlexDuck :: Tables'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'map',
    component: MapsComponent,
    data: { title: ':: FlexDuck :: Maps'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'gallery',
    component: GalleryComponent,
    data: { title: ':: FlexDuck :: Gallery'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'charts',
    component: ChartsComponent,
    data: { title: ':: FlexDuck :: Charts'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'formelement',
    component: FormElementComponent,
    data: { title: ':: FlexDuck :: Forms'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: ':: FlexDuck :: Settings'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: { title: ':: FlexDuck :: Profile'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'widgets',
    component: WidgetsComponent,
    data: { title: ':: FlexDuck :: Widgets'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'widgets-card',
    component: WCardComponent,
    data: { title: ':: FlexDuck :: Widgets'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'widgets-statistics',
    component: WStaticsComponent,
    data: { title: ':: FlexDuck :: Widgets'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'widgets-data',
    component: WDataComponent,
    data: { title: ':: FlexDuck :: Widgets'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'widgets-social',
    component: WSocialComponent,
    data: { title: ':: FlexDuck :: Widgets'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
  {
    path: 'widgets-other',
    component: WOtherComponent,
    data: { title: ':: FlexDuck :: Widgets'},
    canActivate: [UserTypeGuard, AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientRoutingModule {
  static components = [
  ];

}
