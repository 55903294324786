export const environment = {
  production: true,
  apiUrl: 'http://192.168.15.176:8000',
  accessToken: 'APP_USR-5052253783798702-080214-ddd8eb234a1e69bc638e95f61947d890-146511220',
  firebaseConfig: {
    apiKey: 'AIzaSyCL6r2YPW5rmiQBYiXsSXGUaBddM1q8lwA',
    authDomain: 'loja-virtual-tibet.firebaseapp.com',
    projectId: 'loja-virtual-tibet',
    storageBucket: 'loja-virtual-tibet.appspot.com',
    messagingSenderId: '544378988266',
    appId: '1:544378988266:web:e2f07a2cb07e9cf58e1993',
    measurementId: 'G-WVY41EJ3X1'
  }
};
