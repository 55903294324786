import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly userTypeKey = 'userType';

  constructor() { }

  setUserType(userType: string) {
    localStorage.setItem(this.userTypeKey, userType);
  }

  getUserType() {
    return localStorage.getItem(this.userTypeKey);
  }
}
