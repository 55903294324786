import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-elements',
  templateUrl: './ui-elements.component.html',
  styleUrls: ['./ui-elements.component.scss']
})
export class UIELEMENTSComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
