import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pr-todolist',
  templateUrl: './pr-todolist.component.html',
  styleUrls: ['./pr-todolist.component.scss']
})
export class PrTodolistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
