import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import firebase from 'firebase';

@Pipe({ name: 'filterByProdutos' })
export class FilterByProdutos implements PipeTransform {
  transform(value: string[]): string[] {
    return value.filter(tamanho => tamanho.match(/[A-Za-z]/));
  }
}

interface ImageFile {
  file: File;
  url: string;
  firebaseURL?: string;
}

interface Product {
  deleted: boolean;
  id: string;
  name: string;
  description: string;
  reference: string;
  images: { url: string }[];
  sizes: Size[];
}

interface Size {
  color: string;
  name: string;
  price: number;
  stock: number;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-appPortal-estoque',
  templateUrl: './appPortal-estoque.component.html',
  styleUrls: ['./appPortal-estoque.component.scss']
})
export class AppPortalEstoqueComponent implements OnInit {
  viewTab = true;
  addTab = false;
  editTab = false;
  produtos: Product[] = [];
  allProdutos: Product[] = [];
  produtoSelecionado: Product | null = null;
  loading = false;
  productForm: FormGroup;
  imageUploadProgress: number[] = [];
  editMode: boolean;
  viewMode: boolean;
  isUploading: boolean[] = [];
  searchText = '';
  tempImages: ImageFile[] = [];

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore
  ) {
    this.productForm = this.fb.group({
      deleted: false,
      name: ['', Validators.required],
      description: ['', Validators.required],
      reference: ['', Validators.required],
      images: this.fb.array([]),
      sizes: this.fb.array([])
    });
  }

  get images(): FormArray {
    return this.productForm.get('images') as FormArray;
  }

  get sizes(): FormArray {
    return this.productForm.get('sizes') as FormArray;
  }

  ngOnInit(): void {
    this.onTab('1');
    this.carregarTodosProdutos();
  }

  // tslint:disable-next-line:variable-name
  onTab(number: string): void {
    this.viewTab = false;
    this.addTab = false;
    this.editTab = false;
    this.editMode = true;

    if (number === '1') {
      this.viewTab = true;
    } else if (number === '2') {
      this.addTab = true;
    } else if (number === '3') {
      this.editTab = true;
      this.editMode = true;
    }
  }

  carregarTodosProdutos(): void {
    this.loading = true;
    this.firestore.collection('products').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Product;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    ).subscribe((data: Product[]) => {
      this.produtos = data;
      this.allProdutos = data;
      this.loading = false;
    }, error => {
      console.error('Erro ao carregar produtos:', error);
      this.loading = false;
    });
  }

  buscarProdutosParam(searchText: string): void {
    // console.log('Texto de busca:', searchText);
    console.log('Todos os produtos:', this.allProdutos);

    if (searchText) {
      this.loading = true;
      this.produtos = this.allProdutos.filter(produto =>
        (produto.name && produto.name.toLowerCase().includes(searchText.toLowerCase())) ||
        (produto.reference && produto.reference.toLowerCase().includes(searchText.toLowerCase()))
      );

      // console.log('Produtos encontrados:', this.produtos);

      if (this.produtos.length === 0) {
        this.produtoNaoEncontrado();
      }
      this.loading = false;
    } else {
      this.produtos = [...this.allProdutos];
      // console.log('Busca limpa, restaurando todos os produtos:', this.produtos);
    }
  }

  limparBusca(): void {
    this.searchText = '';
    this.produtos = [...this.allProdutos];
  }

  async produtoNaoEncontrado(): Promise<void> {
    await Swal.fire({
      title: 'Produto não encontrado',
      text: 'Nenhum produto foi encontrado.',
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }

  onOpenModalClick(mode: string): void {
    if (mode === 'add') {
      this.editMode = false;
      this.viewMode = false;
      this.productForm.reset();
      this.images.clear();
      this.sizes.clear();
    } else if (mode === 'edit') {
      this.editMode = true;
      this.viewMode = false;
    } else if (mode === 'view') {
      this.editMode = false;
      this.viewMode = true;
    }
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
      const backdrop = document.createElement('div');
      backdrop.className = 'modal-backdrop fade show';
      document.body.appendChild(backdrop);
    }
  }

  onCloseModalClick(): void {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.classList.remove('show');
        setTimeout(() => {
          if (backdrop.parentNode) {
            backdrop.parentNode.removeChild(backdrop);
          }
        }, 200);
      }
    }
    this.editMode = false;
    this.viewMode = false;
    this.productForm.reset();
    this.images.clear();
    this.sizes.clear();
  }

  criarProduto(): void {
    const newProduct = this.productForm.value;
    const tempImages = [...this.tempImages]; // Clone as imagens temporárias

    // Mostrar carregamento
    Swal.fire({
      title: 'Criando produto...',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    // Adicionar o novo produto à coleção
    this.firestore.collection('products').add(newProduct).then((docRef) => {
      const productId = docRef.id;

      // Processar upload das imagens
      const uploadPromises = tempImages.map((image, index) => {
        if (image.file) {
          // console.log(`Iniciando upload da imagem ${index}:`, image.file);
          return this.uploadImageToStorage(image.file, productId, index).then((downloadURL) => {
            // console.log(`Imagem ${index} upload completo:`, downloadURL);
            // Atualizar o campo 'url' da imagem na coleção de imagens
            const imageControl = this.images.at(index);
            if (imageControl) {
              imageControl.get('url')?.setValue(downloadURL);
              // console.log(`URL da imagem ${index} atualizada no controle:`, downloadURL);
            } else {
              // console.error(`Controle da imagem no índice ${index} não encontrado.`);
            }
          });
        } else {
          // console.log(`Nenhum arquivo para upload na imagem ${index}.`);
          return Promise.resolve();
        }
      });

      return Promise.all(uploadPromises).then(() => {
        // Substituir as URLs base64 pelas URLs do Firebase nas imagens temporárias
        const updatedImages = newProduct.images.map((image: any, index: number) => {
          if (tempImages[index] && tempImages[index].firebaseURL) {
            return { ...image, url: tempImages[index].firebaseURL };
          }
          return image;
        });

        newProduct.images = updatedImages;

        // console.log('Atualizando produto com as novas informações:', newProduct);
        return this.firestore.collection('products').doc(productId).update(newProduct);
      }).then(() => {
        Swal.close();
        // console.log('Produto criado com sucesso!');
        return Swal.fire({
          title: 'Produto criado com sucesso!',
          icon: 'success',
          confirmButtonColor: 'rgb(255,151,0)',
          confirmButtonText: 'OK'
        });
      }).then(() => {
        this.productForm.reset(); // Limpar o formulário
        this.tempImages = []; // Limpar imagens temporárias
        // console.log('Imagens temporárias limpas:', this.tempImages);
        this.onTab('1');
        this.editMode = false;
        this.onCloseModalClick();
      }).catch(error => {
        // console.error('Erro ao criar produto:', error);
        Swal.close();
        return Swal.fire({
          title: 'Erro ao criar produto',
          text: 'Ocorreu um erro ao tentar criar o produto.',
          icon: 'error',
          confirmButtonColor: 'rgb(220, 53, 69)',
          confirmButtonText: 'OK'
        });
      });
    }).catch(error => {
      // console.error('Erro ao adicionar produto à coleção:', error);
      Swal.close();
      return Swal.fire({
        title: 'Erro ao adicionar produto',
        text: 'Ocorreu um erro ao tentar adicionar o produto.',
        icon: 'error',
        confirmButtonColor: 'rgb(220, 53, 69)',
        confirmButtonText: 'OK'
      });
    });
  }



  editarProduto(): void {
    if (this.produtoSelecionado) {
      const updatedProduct = this.productForm.value;
      const productId = this.produtoSelecionado.id;
      const tempImages = [...this.tempImages]; // Clonar as tempImages

      console.log('Produto selecionado:', this.produtoSelecionado);
      console.log('Produto antes da atualização:', updatedProduct);
      console.log('Imagens temporárias:', tempImages);

      // Mostrar carregamento
      Swal.fire({
        title: 'Atualizando produto...',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      const uploadPromises = tempImages.map((image, index) => {
        if (image && image.file) {
          console.log(`Iniciando upload da imagem ${index}:`, image.file);
          return this.uploadImageToStorage(image.file, productId, index).then((downloadURL) => {
            console.log(`Imagem ${index} upload completo:`, downloadURL);
            // Atualizar o campo 'url' da imagem na coleção de imagens
            const imageControl = this.images.at(index);
            if (imageControl) {
              imageControl.get('url')?.setValue(downloadURL);
              console.log(`URL da imagem ${index} atualizada no controle:`, downloadURL);
            } else {
              console.error(`Controle da imagem no índice ${index} não encontrado.`);
            }
          });
        } else {
          console.log(`Nenhum arquivo para upload na imagem ${index}.`);
          return Promise.resolve();
        }
      });

      Promise.all(uploadPromises)
        .then(() => {
          // Substituir as URLs base64 pelas URLs do Firebase nas imagens temporárias
          const updatedImages = updatedProduct.images.map((image: any, index: number) => {
            if (tempImages[index] && tempImages[index].firebaseURL) {
              return { ...image, url: tempImages[index].firebaseURL };
            }
            return image;
          });

          updatedProduct.images = updatedImages;

          console.log('Atualizando produto com as novas informações:', updatedProduct);
          return this.firestore.collection('products').doc(productId).update(updatedProduct);
        })
        .then(() => {
          Swal.close();
          console.log('Produto atualizado com sucesso!');
          return Swal.fire({
            title: 'Produto atualizado com sucesso!',
            icon: 'success',
            confirmButtonColor: 'rgb(255,151,0)',
            confirmButtonText: 'OK'
          });
        })
        .then(() => {
          this.tempImages = []; // Limpar imagens temporárias
          console.log('Imagens temporárias limpas:', this.tempImages);
          this.onTab('1');
          this.onCloseModalClick();
        })
        .catch(error => {
          console.error('Erro ao atualizar produto:', error);
          Swal.close();
          return Swal.fire({
            title: 'Erro ao atualizar produto',
            text: 'Ocorreu um erro ao tentar atualizar o produto.',
            icon: 'error',
            confirmButtonColor: 'rgb(220, 53, 69)',
            confirmButtonText: 'OK'
          });
        });
    }
  }


  selecionarProdutoParaEdicao(produto: Product): void {
    this.produtoSelecionado = produto;
    this.editMode = true;
    this.viewMode = false;

    this.productForm.patchValue({
      name: produto.name,
      description: produto.description,
      reference: produto.reference
    });

    this.images.clear();
    this.sizes.clear();

    produto.images.forEach(image => this.images.push(this.fb.group({ url: [image.url, Validators.required] })));
    produto.sizes.forEach(size => this.sizes.push(this.fb.group({
      color: [size.color, Validators.required],
      name: [size.name, Validators.required],
      price: [size.price, Validators.required],
      stock: [size.stock, Validators.required]
    })));

    this.onOpenModalClick('edit');
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  isObject(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }

  limparFormulario(): void {
    this.productForm.reset();
    this.onTab('1');
  }

  adicionarImagem(): void {
    this.images.push(this.fb.group({ url: ['', Validators.required] }));
    this.tempImages.push({ file: null, url: '' }); // Initialize the tempImages array
  }


  removerImagem(index: number): void {
    this.images.removeAt(index);
  }

  uploadImage(event: any, index: number): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.tempImages[index] = { file, url: e.target.result };
        this.images.at(index).get('url').setValue(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }


  adicionarTamanho(): void {
    this.sizes.push(this.fb.group({
      color: ['', Validators.required],
      name: ['', Validators.required],
      price: [0, Validators.required],
      stock: [0, Validators.required]
    }));
  }

  removerTamanho(index: number): void {
    this.sizes.removeAt(index);
  }

  hasImageUploadProgress(index: number): boolean {
    return this.imageUploadProgress[index] !== undefined && this.imageUploadProgress[index] !== null;
  }

  displayValue(value: any, type: string = 'text'): any {
    if (type === 'number') {
      return value !== null && value !== undefined ? value : 0;
    }
    return value ? value : '--';
  }

  visualizarProduto(produto: Product): void {
    this.produtoSelecionado = produto;
    this.editMode = false;
    this.viewMode = true;
    this.productForm.patchValue({
      name: produto.name,
      description: produto.description,
      reference: produto.reference
    });
    this.images.clear();
    this.sizes.clear();
    produto.images.forEach(image => this.images.push(this.fb.group({ url: [image, Validators.required] })));
    produto.sizes.forEach(size => this.sizes.push(this.fb.group({
      color: [size.color, Validators.required],
      name: [size.name, Validators.required],
      price: [size.price, Validators.required],
      stock: [size.stock, Validators.required]
    })));
    this.onOpenModalClick('view');
  }

  deletarProduto(produto: Product): void {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Esta ação não pode ser desfeita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(255,151,0)',
      cancelButtonColor: 'rgb(220,53,69)',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.firestore.collection('products').doc(produto.id).update({ deleted: true }).then(() => {
          produto.deleted = true;
          Swal.fire({
            title: 'Produto excluído com sucesso!',
            icon: 'success',
            confirmButtonColor: 'rgb(255,151,0)',
            confirmButtonText: 'OK'
          });
        }).catch(error => {
          console.error('Erro ao excluir produto:', error);
          Swal.fire({
            title: 'Erro',
            text: 'Erro ao excluir produto, por favor tente novamente.',
            icon: 'error',
            confirmButtonColor: 'rgb(255,151,0)',
            confirmButtonText: 'OK'
          });
        });
      }
    });
  }

  searchButton(): void {
    document.getElementById('searchButton')?.click();
  }

  uploadImageToStorage(file: File, productId: string, index: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef.child(`products/${productId}/${file.name}`).put(file);

      // Atualizar progresso do upload
      uploadTask.on('state_changed',
        (snapshot) => {
          // Calcular progresso
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.imageUploadProgress[index] = progress; // Atualizar progresso
          // console.log(`Progresso do upload da imagem ${index}: ${progress}%`);
        },
        (error) => {
          // console.error('Erro ao fazer upload da imagem:', error);
          this.imageUploadProgress[index] = null; // Resetar progresso em caso de erro
          reject(error);
        },
        () => {
          // Concluir upload e obter URL
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.tempImages[index].firebaseURL = downloadURL; // Atualizar com a URL do Firebase
            this.imageUploadProgress[index] = null; // Resetar progresso após upload
            // console.log(`Imagem ${index} upload completo: ${downloadURL}`);
            resolve(downloadURL);
          }).catch(error => {
            // console.error('Erro ao obter URL de download:', error);
            this.imageUploadProgress[index] = null; // Resetar progresso em caso de erro
            reject(error);
          });
        }
      );
    });
  }



}
