import {AfterViewInit, Component, OnInit, Output} from '@angular/core';
import Swal from 'sweetalert2';
import {ClienteService} from '../../../../../../core/services/pdv/clients.service';
import {Client} from '../../../../../../core/_models/clients';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {SharedService} from '../../../../../../core/services/pdv/SharedService';

@Component({
  selector: 'app-add-client-modal',
  templateUrl: './addClient.component.html',
  styleUrls: ['./addClient.component.scss']
})

export class AddClientComponent implements OnInit, AfterViewInit {

  constructor(private clientsService: ClienteService,
              private http: HttpClient, private fb: FormBuilder, public dialogRef: MatDialogRef<AddClientComponent>,
              public sharedService: SharedService) { }
  protected clients: Client[];
  private historicoCliente: any;
  protected maiorCompra: any;
  protected Tickets: any;
  protected compraMedia: any;
  searchText: any;
  protected newId = '';
  isSwitchOn: any;
  ufDdds: { [key: string]: string[] } = {
    AC: ['68'],
    AL: ['82'],
    AP: ['96'],
    AM: ['92'],
    BA: ['71', '73', '74', '75', '77'],
    CE: ['85'],
    DF: ['61'],
    ES: ['27', '28'],
    GO: ['62'],
    MA: ['98'],
    MT: ['65'],
    MS: ['67'],
    MG: ['31', '32', '33', '34', '35', '37', '38'],
    PA: ['91', '93', '94'],
    PB: ['83'],
    PR: ['41', '42', '43', '44', '45', '46'],
    PE: ['81', '87'],
    PI: ['86'],
    RJ: ['21', '22', '24'],
    RN: ['84'],
    RS: ['51', '53', '54', '55'],
    RO: ['69'],
    RR: ['95'],
    SC: ['47', '48', '49'],
    SP: ['11', '12', '13', '14', '15', '16', '17', '18', '19'],
    SE: ['79'],
    TO: ['63']
  };
  ultimaCompra: Date;
  aniversario: Date;
  selectedUf: string | undefined;
  selectedDdd: string | undefined;
  cepInput: string;
  protected TIPO_VAREJO = '';
  protected TIPO_TELEFONE = '';
  protected endereco: string | undefined;
  protected tipoLogradouro: string | undefined;
  protected logradouro: string | undefined;
  protected bairro: string | undefined;
  protected cidade: string | undefined;
  protected uf = '';
  protected Sexo = '';
  protected ESTADO_CIVIL = -1;
  clienteForm: FormGroup;


  // Configuração do formato da data
  datepickerConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: 'DD/MM/YYYY'
  };

  ngOnInit(): void {
    this.clienteForm = this.fb.group({
      CODIGO_CLIENTE: [''],
      PF_PJ: [false],
      CLIENTE_VAREJO: [''],
      APELIDO: [''],
      SEXO: [''],
      ESTADO_CIVIL: -1,
      PROFISSAO: [''],
      RG_IE: [''],
      CPF_CGC: [''],
      CEP: [''],
      TIPO_LOGRADOURO: [''],
      ENDERECO: [''],
      NUMERO: [''],
      UF: [''],
      COMPLEMENTO: [''],
      BAIRRO: [''],
      CIDADE: [''],
      TIPO_TELEFONE: [''],
      DDD: [''],
      TELEFONE: [''],
      FAX: [''],
      EMAIL: [''],
      TIPO_VAREJO: [''],
      CARTAO_AFINIDADE: [''],
      ULTIMA_COMPRA: [''],
      ANIVERSARIO: [''],
      OBS: ['']
    });

  }

  ngAfterViewInit(): void {
    this.buscarLastIdClient();
  }

  onUfChange(event: any): void {
    const uf = event.target.value;
    this.selectedUf = uf;
    this.selectedDdd = this.ufDdds[uf][0];
  }

  async confirmarExclusao(): Promise<void> {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      Swal.fire(
        'Deleted!',
        'User has been deleted.',
        'success'
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        '',
        'error'
      );
    }
  }

  onOpenModalClick(): void {
    console.log('Botão Abrir Modal clicado');
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  }

  onCloseModalClick(): void {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
  }

  async clienteNaoEncontrado(): Promise<void> {
    await Swal.fire({
      title: 'Cliente não encontrado',
      text: 'Nenhum cliente foi encontrado.',
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }


  buscarLastIdClient(): void {
    this.clientsService.buscarUltimoIdClients().pipe(
      catchError(error => {
        console.error('Ocorreu um erro ao buscar o último ID:', error);
        return throwError(error);
      }),
      map((response: any[]) => {
        console.log(response);
        if (response && response.length > 0) {
          const lastId = response[0].CODIGO_CLIENTE;
          let nextId = lastId + 1; // Incrementa o ID
          this.newId = 'FLX-D' + nextId.toString().padStart(6, '0'); // Formata o novo ID
          return this.newId;
        } else {
          return 'ERROR-STOP';
        }
      })
    ).subscribe((newId: string) => {
      this.isSwitchOn = true;
      this.clienteForm.patchValue({ CODIGO_CLIENTE: newId });
    });
  }

  formatCEP(event: any): void {
    let value = event.target.value;
    value = value.replace(/\D/g, '');
    if (value.length === 8) {
      value = value.substring(0, 5) + '-' + value.substring(5);
      this.buscarEndereco(value);
    }
    event.target.value = value;
  }

  formatCPF(event: any): void {
    let value = event.target.value;
    value = value.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (value.length === 11) { // Verifica se o CPF está completo
      value = value.substring(0, 3) + '.' + value.substring(3, 6) + '.' + value.substring(6, 9) + '-' + value.substring(9);
    }
    event.target.value = value; // Atualiza o valor no campo
  }

  // Função para extrair o tipo de logradouro
  extrairTipoLogradouro(logradouro: string): string {
    const partes = logradouro.split(' ');
    return partes[0];
  }

  // Função para extrair o restante do nome do logradouro
  extrairRestanteLogradouro(logradouro: string): string {
    const partes = logradouro.split(' ');
    partes.shift();
    return partes.join(' ');
  }


  // Na função buscarEndereco, após receber os dados do endereço:
  buscarEndereco(cep: string): void {
    this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`).subscribe(
      (data) => {
        console.log(data);
        // Verificar se os dados do endereço foram recuperados corretamente
        if (data && data.logradouro) {
          // Atribuir os dados do endereço às variáveis do componente
          this.tipoLogradouro = this.extrairTipoLogradouro(data.logradouro);
          this.logradouro = this.extrairRestanteLogradouro(data.logradouro);
          this.bairro = data.bairro;
          this.cidade = data.localidade;
          this.uf = data.uf;
        } else {
          console.error('Dados de endereço incompletos:', data);
        }
      },
      (error) => {
        console.error('Erro ao buscar endereço:', error);
      }
    );
  }


  limparFormulario(): void {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
    this.clienteForm.reset();
  }

  fecharModal(): void {
    this.dialogRef.close();
  }


  async clienteCadastrado(): Promise<void> {
    await Swal.fire({
      title: 'Sucesso!',
      text: 'O cliente foi cadastrado com sucesso',
      icon: 'success',
      confirmButtonColor: 'rgb(243,171,67)',
      confirmButtonText: 'OK'
    });
    this.limparFormulario();
  }

  async dadosNaoPreenchidos(camposFaltantes: string[]): Promise<void> {
    if (camposFaltantes.length === 1) {
      const nomeCampo = {
        CLIENTE_VAREJO: 'Nome',
        CPF_CGC: 'CPF',
        CEP: 'CEP',
        TIPO_VAREJO: 'Tipo do Cliente',
        TELEFONE: 'Telefone'
      };

      const nomeAmigavel = nomeCampo[camposFaltantes[0]].bold() || camposFaltantes[0].bold();

      await Swal.fire({
        title: 'Dados Incompletos',
        html: `O campo <b>${nomeAmigavel}</b> não foi preenchido.`,
        icon: 'error',
        confirmButtonColor: 'rgb(220, 53, 69)',
        confirmButtonText: 'OK'
      });
    } else {
      await Swal.fire({
        title: 'Dados Incompletos',
        html: `Por favor, preencha todos os campos <b>obrigatórios*</b>.`,
        icon: 'error',
        confirmButtonColor: 'rgb(220, 53, 69)',
        confirmButtonText: 'OK'
      });
    }
  }


  adicionarCliente() {
    // tslint:disable-next-line:variable-name
    const current_dateTime = new Date();
    // tslint:disable-next-line:variable-name
    const formatted_dateTime = this.formatDateTime(current_dateTime);
    const timestampHex = '0x' + current_dateTime.getTime().toString(16).toUpperCase();

    const novoClienteFormValue = this.clienteForm.value;

    // Converter todas as propriedades para maiúsculas
    Object.keys(novoClienteFormValue).forEach(key => {
      if (typeof novoClienteFormValue[key] === 'string') {
        novoClienteFormValue[key] = novoClienteFormValue[key].toUpperCase();
      }
    });

    // Verificar se os campos obrigatórios estão preenchidos
    const camposObrigatorios = ['CLIENTE_VAREJO', 'CPF_CGC', 'CEP', 'TIPO_VAREJO', 'TELEFONE'];
    const camposFaltantes = camposObrigatorios.filter(key => !novoClienteFormValue.hasOwnProperty(key) || !novoClienteFormValue[key]);

    if (camposFaltantes.length > 0) {
      this.dadosNaoPreenchidos(camposFaltantes);
      console.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    const novoCliente: Client = novoClienteFormValue;

    novoCliente.CODIGO_CLIENTE = this.newId.toUpperCase();
    novoCliente.CADASTRAMENTO = formatted_dateTime;
    novoCliente.FILIAL = this.sharedService.userFilial.toUpperCase();
    novoCliente.PAIS = 'BRASIL';
    // tslint:disable-next-line:radix
    novoCliente.ESTADO_CIVIL = parseInt(String(novoCliente.ESTADO_CIVIL));

    if (typeof novoCliente.ULTIMA_COMPRA === 'string' && novoCliente.ULTIMA_COMPRA === '') {
      novoCliente.ULTIMA_COMPRA = null;
    }

    this.clientsService.adicionarCliente(novoCliente).subscribe(
      response => {
        console.log(novoCliente);
        console.log('Cliente adicionado com sucesso:', response);
        this.clienteCadastrado();
        // Faça o que for necessário após adicionar o cliente
      },
      error => {
        console.error('Erro ao adicionar cliente:', error);
        // Trate o erro conforme necessário
      }
    );
  }

  formatDateTime(date: Date): Date {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return new Date(year, month, day, hours, minutes, seconds);
  }





}
