import {Component, OnInit} from '@angular/core';
import {TokenService} from '../../../../core/services/pdv/DecryptionService';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-appPortal-dashboard',
  templateUrl: './appPortal-dashboard.component.html',
  styleUrls: ['./appPortal-dashboard.component.scss']
})
export class AppPortalDashboardComponent implements OnInit {
  protected currentUser: any;


  constructor(private decryptionService: TokenService) { }

  ngOnInit(): void {
    this.Decrypt();
  }

  Decrypt(): any {
    const decodedToken = this.decryptionService.decodeTokenFromLocalStorage();

    if (decodedToken && decodedToken.sub) {
      const { PASSWORD, ...configuracoesSemSenha } = decodedToken.sub;
      // console.log(decodedToken.sub);

      const user = decodedToken.sub.USER;
      this.currentUser = user.charAt(0).toUpperCase() + user.slice(1);

    } else {
      return null;
    }
  }

}
