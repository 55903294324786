import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Filial } from '../../_models/filial';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilialService {

  private baseUrl = `${environment.apiUrl}/filiais`;

  constructor(private http: HttpClient) { }

  // Método para buscar todas as filiais
  buscarTodasFiliais(): Observable<Filial[]> {
    return this.http.get<Filial[]>(this.baseUrl);
  }
}
