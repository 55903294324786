import {Component, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import {Client} from '../../../../../../core/_models/clients';
import Swal from 'sweetalert2';
import {ClienteService} from '../../../../../../core/services/pdv/clients.service';
import {SharedService} from '../../../../../../core/services/pdv/SharedService';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Pipe({
  name: 'filter'
})
export class FilterPipeC implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText || !searchText.trim()) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter((item) => {
      const keys = Object.keys(item);
      return keys.some((key) => item[key] && item[key].toString().toLowerCase().includes(searchText));
    });
  }
}


@Component({
  selector: 'app-search-client-modal',
  templateUrl: './searchClient.component.html',
  styleUrls: ['./searchClient.component.scss']
})
export class SearchClientComponent implements OnInit {
  clientes: Client[] = [];
  pesquisaClient = '';
  activeModal: any;
  private selectedClienteName!: string;
  private selectedClienteCPF_CNPJ!: string;
  private selectedClienteTelephone!: string;
  private SelectedClienteId!: string;
  loading: boolean;


  // tslint:disable-next-line:max-line-length
  constructor(private clientsService: ClienteService, public sharedService: SharedService,
              public dialogRef: MatDialogRef<SearchClientComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pesquisaClient = data.valorPesquisa;
  }

  ngOnInit() {
    // setTimeout(() => {
    //   this.isLoading = false; // Após o carregamento dos dados, definimos loading como false
    // }, 2000);
  }

  buscarClientsParam(clientsParam: string): void {
    this.loading = true;
    // Verificar se o parâmetro de busca está presente
    if (clientsParam) {
      // Chamar o serviço para buscar os clientes com base no parâmetro
      this.clientsService.buscarClientsByParam(clientsParam).subscribe(
        (response: Client[] | any) => {
          // Verificar se a resposta é um array e se está vazio
          if (Array.isArray(response) && response.length === 0) {
            // Nenhum cliente encontrado, exibir uma mensagem de aviso
            this.clienteNaoEncontrado();
            this.clientes = [];
            this.loading = false;
          } else {
            // Clientes encontrados, atribuir à lista de clientes
            this.clientes = response;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          console.error('Erro ao buscar clientes:', error);
        }
      );
    } else {
      this.loading = false;
      console.error('Parâmetro de busca do cliente não fornecido');
    }
  }

  async clienteNaoEncontrado(): Promise<void> {
    this.loading = false;
    await Swal.fire({
      title: 'Cliente não encontrado',
      text: 'Nenhum cliente foi encontrado.',
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }


    selecionarClient(cliente: Client) {
    // Verifica se o objeto selectedCliente está definido
    if (!this.sharedService.selectedCliente) {
      this.sharedService.selectedCliente = {} as Client;
    }

    // Atualiza os valores compartilhados do cliente
    if (cliente.CLIENTE_VAREJO != null) {
      this.sharedService.selectedCliente.CLIENTE_VAREJO = cliente.CLIENTE_VAREJO;
    }
    this.sharedService.selectedCliente.CPF_CGC = String(cliente.CPF_CGC?.toString());
    this.sharedService.selectedCliente.TELEFONE = String(cliente.TELEFONE?.toString());
    this.sharedService.selectedCliente.CODIGO_CLIENTE = String(cliente.CODIGO_CLIENTE?.toString());

    // Fecha o modal
    this.fecharModal();

    this.sharedService.updateFieldsClient();
    this.updateFields();
  }

  fecharModal(): void {
    this.dialogRef.close();
  }


  updateFields() {
    this.selectedClienteName = this.sharedService.selectedCliente.CLIENTE_VAREJO;
    this.selectedClienteCPF_CNPJ = this.sharedService.selectedCliente.CPF_CGC;
    this.selectedClienteTelephone = this.sharedService.selectedCliente.TELEFONE;
    this.SelectedClienteId = this.sharedService.selectedCliente.CODIGO_CLIENTE;
  }

}
