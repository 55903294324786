import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VendaService {

  private baseUrl = `${environment.apiUrl}/vendas`;

  constructor(private http: HttpClient) {
  }

  // Método para buscar as operações de vendas
  buscarOperacoesVendas(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/getOperacoes`);
  }

  // Método para buscar as tabelas de preços de venda
  buscarTabelasPrecos(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/getTabelaPreco`);
  }

  // Método para sincronizar com o LINX
  syncronLinx(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/syncron`);
  }

  buscarLastSaleID(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/lastSaleID`);
  }

  buscarPaymentsMethods(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/paymentsMethods`);
  }

  buscarTipoPgto(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/tipoPgto`);
  }

  abrirPedido(newPedido: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/openPedido`, newPedido);
  }

  inserirProdutos(listaProdutos: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/insertProductsPedido`, listaProdutos);
  }

  inserirPagamento(dadosPagamentoPedido: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/insertPaymentPedido`, dadosPagamentoPedido);
  }

  cancelarPedido(dadosPedido: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/cancelPedido`, dadosPedido);
  }

  geraQrCodeFlexDuck(dadosQrCodeFlexDuck: any): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'image/png' // Define que aceita uma imagem PNG como resposta
    });

    return this.http.post(`${environment.apiUrl}/api/payments/gerar-qrcode`, dadosQrCodeFlexDuck, {
      headers,
      responseType: 'blob' // Define o tipo de resposta como Blob
    });
  }

}
