import {Component, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SearchClientComponent} from '../../../pdv-clients/_modals/search/searchClient.component';
import {SharedService} from '../../../../../../core/services/pdv/SharedService';
import {PrecoProduto} from '../../../../../../core/_models/precoProduto';
import {EstoqueService} from '../../../../../../core/services/pdv/estoque.service';
import Swal from 'sweetalert2';
import {EstoqueProduto} from '../../../../../../core/_models/estoque';

@Pipe({
  name: 'filter'
})
export class FilterPipeP implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText || !searchText.trim()) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter((item) => {
      const keys = Object.keys(item);
      return keys.some((key) => item[key] && item[key].toString().toLowerCase().includes(searchText));
    });
  }
}

@Component({
  selector: 'app-produto-modal',
  templateUrl: './produtoModal.component.html',
  styleUrls: ['./produtoModal.component.scss']
})

export class ProdutoModalComponent implements OnInit {
  produtos: PrecoProduto[] = [];
  pesquisaProduto = '';
  protected codigoProduto: string;
  protected coresDisponiveis: string[];
  protected tamanhosDisponiveis: Set<string>;
  loading: boolean;
  selectedCor: any;
  selectedTamanho: any;

  constructor(private estoqueService: EstoqueService,
              public sharedService: SharedService, public dialogRef: MatDialogRef<ProdutoModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { this.pesquisaProduto = data.valorPesquisa; }

  ngOnInit(): void {
  }

  selecionarProduto(produto: PrecoProduto) {
    // Verifica se o objeto selectedCliente está definido
    if (!this.sharedService.selectedProduto) {
      this.sharedService.selectedProduto = {} as PrecoProduto;
    }

    // Atualiza os valores compartilhados do cliente
    if (produto.DESC_PRODUTO != null) {
      this.sharedService.selectedProduto.DESC_PRODUTO = produto.DESC_PRODUTO;
    }
    this.sharedService.selectedProduto.CODIGO_BARRA = String(produto.CODIGO_BARRA?.toString());


    // Fecha o modal
    this.fecharModal();

    // Chama a função para atualizar os campos de input no SalesComponent
    this.sharedService.updateFieldsProduto();
  }

  fecharModal(): void {
    this.dialogRef.close();
  }

  buscarProdutos(): void {
    this.loading = true;
    // Acessar o elemento HTML do input do código do produto
    const codigoInput: HTMLInputElement | null = document.querySelector('#codigoInput');

    if (!codigoInput) {
      console.error('Elemento de input do código do produto não encontrado');
      return;
    }

    // Atribuir o valor do input como uma string à variável codigoProduto
    this.codigoProduto = codigoInput.value.trim();

    // console.log(this.codigoProduto);

    // Chame o serviço para buscar os produtos com base na filial e no código do produto
    this.estoqueService.buscarPrecoProdutos(this.codigoProduto).subscribe(
      (response: any) => {
        // console.log(response);
        // Verificar se a resposta contém a chave esperada
        if (!response || !response.precoProduto) {
          console.error('Resposta da API inválida:', response);
          return;
        }

        const { precoProduto } = response;

        // Verificar se a resposta contém produtos
        if (!Array.isArray(precoProduto) || precoProduto.length === 0) {
          console.log('Nenhum produto encontrado');
          this.produtoNaoEncontrado();
          this.produtos = [];
          return;
        }

        // Produtos encontrados, continue como antes
        this.produtos = precoProduto;
        this.coresDisponiveis = this.extrairCoresDisponiveis(this.produtos);
        this.tamanhosDisponiveis = this.extrairTamanhosDisponiveis(this.produtos);
        // this.onOpenModalClick();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.error('Erro ao buscar produtos:', error);
      }
    );
  }

  // Método para extrair cores disponíveis dos produtos filtrados
  extrairCoresDisponiveis(produtosFiltrados: PrecoProduto[]): string[] {
    const coresDisponiveis: string[] = [];

    // Iterar sobre os produtos filtrados
    produtosFiltrados.forEach(produto => {
      // Remover espaços em branco extras e adicionar a cor à lista de cores disponíveis, se ainda não estiver presente
      const corLimpa = produto.COR_PRODUTO.trim();
      if (!coresDisponiveis.includes(corLimpa)) {
        coresDisponiveis.push(corLimpa);
      }
    });

    return coresDisponiveis;
  }

  // Método para extrair tamanhos disponíveis dos produtos filtrados
  extrairTamanhosDisponiveis(produtos: any[]): Set<string> {
    const tamanhos: Set<string> = new Set();

    produtos.forEach((produto) => {
      if (produto && produto.TAMANHO) {
        tamanhos.add(produto.TAMANHO.toString().trim());
      }
    });

    return tamanhos;
  }

  async produtoNaoEncontrado(): Promise<void> {
    await Swal.fire({
      title: 'Produto não encontrado',
      text: 'Nenhum produto foi encontrado .',
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }

  filtrarProdutos(): PrecoProduto[] {
    if (!this.selectedCor && !this.selectedTamanho) {
      return this.produtos;
    }

    const produtosFiltrados = this.produtos.filter(produto => {
      // Verificar se a cor do produto corresponde à cor selecionada
      const corCorrespondente = !this.selectedCor || produto.COR_PRODUTO === this.selectedCor;

      // tslint:disable-next-line:max-line-length
      const tamanhoCorrespondente = !this.selectedTamanho || produto.TAMANHO === this.selectedTamanho.trim();

      return corCorrespondente && tamanhoCorrespondente;
    });
    return produtosFiltrados;
  }

}
