import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import firebase from 'firebase';
import { Order } from '../../_models/appPortal/order';
import {environment} from '../../../../environments/environment.prod';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid'; // Para gerar UUIDs


const orderStatus = new Map([
  [0, 'Cancelado'],
  [1, 'Em Preparação'],
  [2, 'Em Transporte'],
  [3, 'Aguardando Retirada'],
  [4, 'Entregue']
]);

const statusBadgeClass = new Map([
  [0, 'badge-danger'],
  [1, 'badge-warning'],
  [2, 'badge-info'],
  [3, 'badge-primary'],
  [4, 'badge-success']
]);

@Injectable({
  providedIn: 'root'
})
export class PedidoService {
  private pedidosCache: Map<string, { timestamp: number, pedido: Order }> = new Map();
  private produtosCache: Map<string, { timestamp: number, produto: any }> = new Map();
  private cacheDuration = 300000; // 5 minutos de cache

  constructor(private firestore: AngularFirestore, private http: HttpClient ) {}

  private isCacheValid(timestamp: number): boolean {
    return (Date.now() - timestamp) < this.cacheDuration;
  }

  getPedidos(): Observable<Order[]> {
    const cachedPedidos = Array.from(this.pedidosCache.values()).map(entry => entry.pedido);
    if (cachedPedidos.length > 0 && cachedPedidos.every(pedido => this.isCacheValid(this.pedidosCache.get(pedido.id)?.timestamp || 0))) {
      return of(cachedPedidos);
    } else {
      return this.firestore.collection('orders').snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Order;
          const id = a.payload.doc.id;
          const date = data.date && data.date.seconds ? new Date(data.date.seconds * 1000) : data.date;
          const pedido: Order = { id, ...data, date };
          this.pedidosCache.set(id, { timestamp: Date.now(), pedido }); // Armazena no cache com timestamp
          return pedido;
        }))
      );
    }
  }

  getClienteNome(clienteId: string): Observable<string> {
    return this.firestore.collection('users').doc(clienteId).get().pipe(
      map(doc => doc.exists ? (doc.data() as any).name : 'Desconhecido')
    );
  }

  getProductDetails(productId: string): Observable<{ name: string; image: string; reference: string }> {
    const cachedProduct = this.produtosCache.get(productId);
    if (cachedProduct && this.isCacheValid(cachedProduct.timestamp)) {
      // Retorna o produto do cache se disponível e válido
      return of(cachedProduct.produto);
    } else {
      return this.firestore.collection('products').doc(productId).get().pipe(
        map(doc => {
          if (doc.exists) {
            const data = doc.data() as any;
            const name = data.name || 'Produto não encontrado';
            const reference = data.reference ? data.reference : '';
            let image = '';

            if (data.images && data.images.length > 0) {
              if (typeof data.images[0] === 'string') {
                image = data.images[0];
              } else if (typeof data.images[0] === 'object' && data.images[0].url) {
                image = data.images[0].url;
              }
            }

            const produto = { name, image, reference };
            this.produtosCache.set(productId, { timestamp: Date.now(), produto }); // Armazena no cache com timestamp
            return produto;
          } else {
            return { name: 'Produto não encontrado', image: '', reference: '' };
          }
        })
      );
    }
  }

  cacheImage(url: string): Observable<string> {
    const cachedImage = localStorage.getItem(url);
    if (cachedImage) {
      return of(cachedImage);
    } else {
      return new Observable<string>(observer => {
        fetch(url).then(response => response.blob()).then(blob => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            localStorage.setItem(url, base64data);
            observer.next(base64data);
            observer.complete();
          };
          reader.readAsDataURL(blob);
        }).catch(error => {
          observer.error(error);
        });
      });
    }
  }

  updatePedido(pedido: Order): Promise<void> {
    this.pedidosCache.set(pedido.id, { timestamp: Date.now(), pedido }); // Atualiza o cache
    return this.firestore.collection('orders').doc(pedido.id).update(pedido);
  }

  updatePedidoStatus(pedidoId: string, statusUpdate: { status: number }): Promise<void> {
    return this.firestore.collection('orders').doc(pedidoId).update(statusUpdate);
  }

  addPedido(pedido: Order): Promise<firebase.firestore.DocumentReference> {
    return this.firestore.collection('orders').add(pedido).then(ref => {
      this.pedidosCache.set(ref.id, { timestamp: Date.now(), pedido: { ...pedido, id: ref.id } }); // Adiciona ao cache
      return ref;
    });
  }

  cancelarPedido(pedidoId: string): Promise<void> {
    this.pedidosCache.delete(pedidoId); // Remove do cache
    return this.firestore.collection('orders').doc(pedidoId).update({ status: 0 });
  }

  cancelMercadoPagoPayment(payId: string): Promise<any> {
    if (!payId) {
      return Promise.reject({
        success: false,
        error: {
          code: 'INVALID_PAYMENT_ID',
          message: 'O ID do pagamento não foi fornecido.',
        }
      });
    }

    const url = `https://api.mercadopago.com/v1/payments/${payId}/refunds`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${environment.accessToken}`,
      'X-Idempotency-Key': uuidv4() // Adiciona o cabeçalho X-Idempotency-Key com um UUID
    });

    return this.http.post<any>(url, {}, { headers, observe: 'response' })
      .toPromise()
      .then(response => {
        console.log('Resposta da API:', response);
        return {
          success: response.status === 201 || response.status === 200,
          message: 'Pagamento cancelado com sucesso',
          data: response.body
        };
      })
      .catch(error => {
        console.error('Erro ao cancelar pagamento:', error);
        return {
          success: false,
          error: {
            code: error.status || 'UNKNOWN_ERROR',
            message: error.error?.message || error.message,
          }
        };
      });
  }



  countOrders(): Observable<number> {
    return this.firestore.collection('orders').get().pipe(
      map(snapshot => snapshot.size)
    );
  }

  getPedidoById(orderId: string): Observable<Order | null> {
    const cachedEntry = this.pedidosCache.get(orderId);
    if (cachedEntry && this.isCacheValid(cachedEntry.timestamp)) {
      // Retorna o pedido do cache se disponível e válido
      return of(cachedEntry.pedido);
    } else {
      return this.firestore.collection('orders').doc(orderId).get().pipe(
        switchMap(doc => {
          if (doc.exists) {
            const data = doc.data() as Order;
            const pedido: Order = { id: doc.id, ...data };

            // Confirma que o paymentId está presente
            if (!pedido.payId) {
              console.error('paymentId não encontrado para o pedido', pedido.id);
            }

            const clienteNome$ = this.getClienteNome(pedido.user);
            const itensDetalhados$ = forkJoin(
              pedido.items.map(item => this.getProductDetails(item.pid).pipe(
                switchMap(details => this.cacheImage(details.image).pipe(
                  map(image => ({
                    ...item,
                    productName: details.name,
                    productReference: details.reference,
                    productImage: image
                  }))
                ))
              ))
            );

            return forkJoin([clienteNome$, itensDetalhados$]).pipe(
              map(([clienteNome, itensDetalhados]) => {
                const pedidoCompleto = {
                  ...pedido,
                  cliente: clienteNome,
                  items: itensDetalhados,
                  statusText: orderStatus.get(pedido.status) || 'Desconhecido',
                  statusBadgeClass: statusBadgeClass.get(pedido.status) || 'badge-secondary'
                };
                this.pedidosCache.set(orderId, { timestamp: Date.now(), pedido: pedidoCompleto }); // Armazena no cache com timestamp
                return pedidoCompleto;
              })
            );
          } else {
            return of(null);
          }
        })
      );
    }
  }

}
