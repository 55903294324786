import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexLegend
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  legend: ApexLegend;
  labels: string[];
};

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent implements OnInit, OnChanges {
  @ViewChild("chart") chart: ChartComponent;
  @Input() salesVolumeData: { date: string, amount: number, units: number }[];
  @Input() chartMode: 'value' | 'units' = 'value';
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Sales Volume",
          data: []
        }
      ],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        type: "datetime"
      },
      yaxis: {
        opposite: false
      },
      legend: {
        horizontalAlign: "left"
      }
    };
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.salesVolumeData || changes.chartMode) {
      this.updateChartData();
    }
  }

  updateChartData(): void {
    const dates = this.salesVolumeData.map(data => data.date);
    const data = this.salesVolumeData.map(data => this.chartMode === 'value' ? data.amount : data.units);

    this.chartOptions = {
      ...this.chartOptions,
      series: [
        {
          name: 'Vendas',
          data: data
        }
      ],
      labels: dates
    };
  }
}
