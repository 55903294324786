import { Component, OnInit } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { INITIAL_EVENTS, createEventId } from './event-utils';
@Component({
  selector: 'app-fullcalender',
  templateUrl: './fullcalender.component.html',
  styleUrls: ['./fullcalender.component.scss']
})
export class FullcalenderComponent implements OnInit {
  // calendarVisible = true;
  feriadosBrasileiros: any[] = [
    { title: 'Ano Novo', start: '2024-01-01', allDay: true },
    { title: 'Carnaval', start: '2024-02-24', end: '2024-02-26', allDay: true },
    { title: 'Paixão de Cristo', start: '2024-04-05', allDay: true },
    { title: 'Tiradentes', start: '2024-04-21', allDay: true },
    { title: 'Dia do Trabalho', start: '2024-05-01', allDay: true },
    { title: 'Independência do Brasil', start: '2024-09-07', allDay: true },
    { title: 'Nossa Senhora Aparecida', start: '2024-10-12', allDay: true },
    { title: 'Finados', start: '2024-11-02', allDay: true },
    { title: 'Proclamação da República', start: '2024-11-15', allDay: true },
    { title: 'Natal', start: '2024-12-25', allDay: true },
  ];

  feriadosES: any[] = [
    { title: 'Dia da Colonização do Solo Espírito-santense', start: '2024-05-23', allDay: true },
    { title: 'Dia de Nossa Senhora da Conceição', start: '2024-12-08', allDay: true },
    { title: 'Dia do Servidor Público', start: '2024-10-28', allDay: true },
    { title: 'Dia da Consciência Negra', start: '2024-11-20', allDay: true },
  ];





  constructor() {
  }

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'dayGridMonth',
    initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    events: [...this.feriadosBrasileiros, ...this.feriadosES], // Adiciona os feriados do Espírito Santo aos eventos
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this)
  };


  currentEvents: EventApi[] = [];

  ngOnInit(): void {
  }

  // handleCalendarToggle() {
  //   this.calendarVisible = !this.calendarVisible;
  // }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const title = prompt('Insira um novo título para o seu evento');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (confirm(`Tem certeza de que deseja excluir o evento '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

}
