import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BloqueioTelaService {
  private bloqueioTelaSubject = new Subject<boolean>();


  bloquearTela() {
    this.bloqueioTelaSubject.next(true);
  }

  desbloquearTela() {
    this.bloqueioTelaSubject.next(false);
  }

  getBloqueioTelaObservable() {
    return this.bloqueioTelaSubject.asObservable();
  }

}
