import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { PedidoService } from '../../../../core/services/appPortal/pedidos.service';
import { Order } from '../../../../core/_models/appPortal/order';

const orderStatus = new Map([
  [0, 'Cancelado'],
  [1, 'Em Preparação'],
  [2, 'Em Transporte'],
  [3, 'Aguardando Retirada'],
  [4, 'Entregue']
]);

const statusBadgeClass = new Map([
  [0, 'badge-danger'],
  [1, 'badge-warning'],
  [2, 'badge-info'],
  [3, 'badge-primary'],
  [4, 'badge-success']
]);

const statusBadgePayment = new Map([
  ['pending', 'badge-warning'],      // O pagamento está pendente
  ['approved', 'badge-success'],     // O pagamento foi aprovado
  ['authorized', 'badge-info'],      // O pagamento foi autorizado mas não capturado
  ['in_process', 'badge-primary'],   // O pagamento está em análise
  ['in_mediation', 'badge-warning'], // O usuário iniciou uma disputa
  ['rejected', 'badge-danger'],      // O pagamento foi rejeitado
  ['failed', 'badge-danger'],        // O pagamento falhou
  ['cancelled', 'badge-danger'],     // O pagamento foi cancelado
  ['refunded', 'badge-secondary'],   // O pagamento foi devolvido ao usuário
  ['charged_back', 'badge-danger']   // Um estorno foi aplicado no cartão
]);

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-appPortal-pedidos',
  templateUrl: './appPortal-pedidos.component.html',
  styleUrls: ['./appPortal-pedidos.component.scss']
})
export class AppPortalPedidosComponent implements OnInit {
  pedidos: any[] = [];
  filteredPedidos: any[] = [];
  loading = false;
  searchText = '';
  filterDate: string;
  pedidoSelecionado: any | null = null;
  novoStatus: number;
  showStatusDropdown = false;
  selectedStatus: number | null = null;

  statusOptionsFilter = [
    { value: 1, label: 'Em Preparação' },
    { value: 2, label: 'Em Transporte' },
    { value: 3, label: 'Aguardando Retirada' },
    { value: 4, label: 'Entregue' }
  ];

  statusOptions = [
    { value: 1, label: 'Em Preparação' },
    { value: 2, label: 'Em Transporte' },
    { value: 3, label: 'Aguardando Retirada' },
    { value: 4, label: 'Entregue' }
  ];

  paymentStatusMessages = new Map([
    ['pending', 'Pendente'],
    ['approved', 'Aprovado'],
    ['authorized', 'Autorizado'],
    ['in_process', 'Processando'],
    ['in_mediation', 'Em Disputa'],
    ['rejected', 'Rejeitado'],
    ['cancelled', 'Cancelado'],
    ['refunded', 'Reembolsado'],
    ['charged_back', 'Estornado'],
    ['failed', 'Falhou'],
    ['unknown', 'Erro']
  ]);

  constructor(private pedidoService: PedidoService) {}

  ngOnInit(): void {
    this.loadPedidos();
  }

  loadPedidos(): void {
    this.loading = true;
    this.pedidoService.getPedidos().pipe(
      switchMap((pedidos: Order[]) => {
        const pedidosComNomes = pedidos.map(pedido => {
          const clienteNome$ = this.pedidoService.getClienteNome(pedido.user);
          const itensDetalhados$ = forkJoin(
            pedido.items.map(item => this.pedidoService.getProductDetails(item.pid).pipe(
              map(details => ({
                ...item,
                productName: details.name,
                productReference: details.reference,
                productImage: details.image
              }))
            ))
          );

          return forkJoin([clienteNome$, itensDetalhados$]).pipe(
            map(([clienteNome, itensDetalhados]) => ({
              ...pedido,
              cliente: clienteNome,
              items: itensDetalhados,
              statusText: orderStatus.get(pedido.status) || 'Desconhecido',
              statusBadgeClass: statusBadgeClass.get(pedido.status) || 'badge-secondary',
              statusBadgePayment: statusBadgePayment.get(pedido.paymentStatus)
            }))
          );
        });
        return forkJoin(pedidosComNomes);
      })
    ).subscribe(
      (pedidos: any[]) => {
        this.pedidos = pedidos.sort((a, b) => b.date - a.date); // Ordenar por data decrescente
        this.filteredPedidos = [...this.pedidos];
        this.loading = false;
      },
      (error) => {
        console.error('Erro ao carregar pedidos:', error);
        this.loading = false;
      }
    );
  }

  buscarPedidos(): void {
    const searchTextInput = document.getElementById('searchTextInput') as HTMLInputElement;
    const searchText = searchTextInput ? searchTextInput.value : '';

    const filterDateInput = document.getElementById('filterDateInput') as HTMLInputElement;
    const filterDate = filterDateInput ? filterDateInput.value : '';

    this.filteredPedidos = this.pedidos.filter(pedido => {
      const matchesSearchText = !searchText ||
        pedido.cliente.toLowerCase().includes(searchText.toLowerCase()) ||
        pedido.id.toString().toLowerCase().includes(searchText.toLowerCase());

      const pedidoDate = new Date(pedido.date);
      const pedidoDateString = !isNaN(pedidoDate.getTime()) ? this.formatDate(pedidoDate) : '';
      const matchesDate = !filterDate || pedidoDateString === filterDate;

      const matchesStatus = this.selectedStatus === null || pedido.status === this.selectedStatus;

      return matchesSearchText && matchesDate && matchesStatus;
    });

    if (this.filteredPedidos.length === 0) {
      this.pedidoNaoEncontrado();
    }

  }

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  limparBusca(): void {
    const searchTextInput = document.getElementById('searchTextInput') as HTMLInputElement;
    const filterDateInput = document.getElementById('filterDateInput') as HTMLInputElement;

    if (searchTextInput) { searchTextInput.value = ''; }
    if (filterDateInput) { filterDateInput.value = ''; }

    this.selectedStatus = null;
    this.filteredPedidos = [...this.pedidos];
  }

  aplicarFiltroData(): void {
    this.buscarPedidos();
  }

  aplicarFiltroStatus(): void {
    this.buscarPedidos();
    this.toggleStatusDropdown();
  }

  toggleStatusDropdown(): void {
    this.showStatusDropdown = !this.showStatusDropdown;
  }

  verDetalhesPedido(pedido: any): void {
    this.pedidoSelecionado = pedido;
    const modal = document.getElementById('detalhesPedidoModal');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      modal.setAttribute('aria-hidden', 'false');
      modal.removeAttribute('aria-hidden');
      modal.setAttribute('aria-modal', 'true');
      document.body.classList.add('modal-open');
      const backdrop = document.createElement('div');
      backdrop.className = 'modal-backdrop fade show';
      document.body.appendChild(backdrop);
    }
  }

  fecharModal(): void {
    this.pedidoSelecionado = null;
    const modal = document.getElementById('detalhesPedidoModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      modal.setAttribute('aria-hidden', 'true');
      modal.removeAttribute('aria-modal');
      document.body.classList.remove('modal-open');
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.classList.remove('show');
        setTimeout(() => {
          if (backdrop.parentNode) {
            backdrop.parentNode.removeChild(backdrop);
          }
        }, 200);
      }
    }
  }

  abrirModalStatus(pedido: any): void {
    this.pedidoSelecionado = pedido;
    this.novoStatus = pedido.status;
    const modal = document.getElementById('alterarStatusModal');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      modal.setAttribute('aria-hidden', 'false');
      modal.removeAttribute('aria-hidden');
      modal.setAttribute('aria-modal', 'true');
      document.body.classList.add('modal-open');
      const backdrop = document.createElement('div');
      backdrop.className = 'modal-backdrop fade show';
      document.body.appendChild(backdrop);
    }
  }

  fecharModalStatus(): void {
    this.pedidoSelecionado = null;
    const modal = document.getElementById('alterarStatusModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      modal.setAttribute('aria-hidden', 'true');
      modal.removeAttribute('aria-modal');
      document.body.classList.remove('modal-open');
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.classList.remove('show');
        setTimeout(() => {
          if (backdrop.parentNode) {
            backdrop.parentNode.removeChild(backdrop);
          }
        }, 200);
      }
    }
  }

  alterarStatusPedido(): void {
    if (this.pedidoSelecionado) {
      const updatedStatus = { status: Number(this.novoStatus) }; // Atualiza apenas o status
      this.pedidoService.updatePedidoStatus(this.pedidoSelecionado.id, updatedStatus).then(() => {
        Swal.fire({
          title: 'Status atualizado com sucesso!',
          icon: 'success',
          confirmButtonColor: 'rgb(255,151,0)',
          confirmButtonText: 'OK'
        });
        this.fecharModalStatus();
        this.loadPedidos(); // Recarregar os pedidos para refletir as mudanças
      }).catch(error => {
        console.error('Erro ao atualizar status do pedido:', error);
        Swal.fire({
          title: 'Erro ao atualizar status',
          text: 'Ocorreu um erro ao tentar atualizar o status do pedido.',
          icon: 'error',
          confirmButtonColor: 'rgb(220, 53, 69)',
          confirmButtonText: 'OK'
        });
      });
    }
  }

  async pedidoNaoEncontrado(): Promise<void> {
    await Swal.fire({
      title: 'Pedido não encontrado',
      text: 'Nenhum pedido foi encontrado.',
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }

  async cancelarPedido(pedido: any): Promise<void> {
    const result = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você deseja realmente cancelar esse pedido?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'Sim, Cancelar!'
    });

    if (result.isConfirmed) {
      try {
        // Cancela o pagamento via Mercado Pago
        const cancelPaymentResponse = await this.pedidoService.cancelMercadoPagoPayment(pedido.payId);

        if (cancelPaymentResponse.success) {
          // Atualiza o status do pedido no Firebase
          await this.pedidoService.cancelarPedido(pedido.id);

          Swal.fire(
            'Cancelado!',
            'Pedido e pagamento cancelados com sucesso.',
            'success'
          );
          this.loadPedidos(); // Recarregar os pedidos para refletir as mudanças
        } else {
          Swal.fire(
            'Erro!',
            'Ocorreu um erro ao cancelar o pagamento.',
            'error'
          );
        }
      } catch (error) {
        console.error('Erro ao cancelar pedido:', error);
        Swal.fire(
          'Erro!',
          'Ocorreu um erro ao cancelar o pedido.',
          'error'
        );
      }
    }
  }

  // Função para obter o rótulo do status
  getPaymentStatusLabel(status: string): string {
    return this.paymentStatusMessages.get(status) || this.paymentStatusMessages.get('unknown');
  }

}
