import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseUserService } from '../../../core/services/appPortal/firebaseUser.service';
import Swal from 'sweetalert2';
import { AngularFirestore } from '@angular/fire/firestore';

interface PaymentMethods {
  mercadoPagoCard: boolean;
  mercadoPagoPix: boolean;
}

interface DeliveryMethods {
  delivery: boolean;
  store_pickup: boolean;
}

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.scss']
})
export class LeftmenuComponent implements OnInit, AfterViewInit  {
  userEmail: string;
  userName: string;
  userPhone: string;
  userCpf: string;
  userAvatar = '../assets/images/user.png';
  isCollapsed = false;
  chatTab = true;
  pixTab = false;
  private userId: string;
  isUpdatingProfile = false;

  mercadoPagoCard = true;
  mercadoPagoPix = true;
  isUpdatingPaymentMethods = false;
  delivery = true;
  storePickup = true;
  isUpdatingDeliveryMethods = false;
  isSidebarCollapsed: boolean;

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private firebaseUserService: FirebaseUserService,
    private firestore: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userId = user.uid;
        this.userEmail = user.email;
        this.loadUserProfile(user.uid);
        this.loadPaymentMethods();
        this.loadDeliveryMethods();
      }
    });
  }

  ngAfterViewInit() {}

  loadUserProfile(uid: string) {
    this.firebaseUserService.getUserData(uid).subscribe(data => {
      this.userName = data.name;
      this.userPhone = data.phone;
      this.userCpf = data.cpf;
      // this.userAvatar = data.avatarUrl || this.userAvatar;
    });
  }

  toggleUserMenu() {
    const body = document.getElementsByClassName('user_div')[0];
    body.classList.toggle('open');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');
  }

  toggleSettingMenu() {
    const body = document.getElementsByClassName('right_sidebar')[0];
    body.classList.toggle('open');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');
  }

  logout() {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/login']);
    });
  }

  saveProfile() {
    const updatedData = {
      name: this.userName,
      phone: this.userPhone,
      cpf: this.userCpf
    };

    this.isUpdatingProfile = true; // Iniciar o estado de carregamento

    this.firebaseUserService.updateUserData(this.userId, updatedData)
      .then(async () => {
        this.isUpdatingProfile = false; // Parar o estado de carregamento
        await Swal.fire({
          title: 'Perfil atualizado com sucesso!',
          icon: 'success',
          confirmButtonColor: 'rgb(255,151,0)',
          confirmButtonText: 'OK'
        });
      })
      .catch(async error => {
        this.isUpdatingProfile = false; // Parar o estado de carregamento
        console.error('Erro ao atualizar o perfil:', error);
        await Swal.fire({
          title: 'Erro ao atualizar o perfil',
          text: error.message || 'Erro desconhecido',
          icon: 'error',
          confirmButtonColor: 'rgb(220, 53, 69)',
          confirmButtonText: 'OK'
        });
      });
  }

  loadPaymentMethods() {
    this.firestore.collection('config').doc('payment_method').get().toPromise().then(doc => {
      if (doc.exists) {
        const data = doc.data() as PaymentMethods;
        this.mercadoPagoCard = data.mercadoPagoCard;
        this.mercadoPagoPix = data.mercadoPagoPix;
      }
    });
  }

  savePaymentMethods() {
    this.isUpdatingPaymentMethods = true; // Iniciar o estado de carregamento
    const paymentMethods: PaymentMethods = {
      mercadoPagoCard: this.mercadoPagoCard,
      mercadoPagoPix: this.mercadoPagoPix
    };

    this.firestore.collection('config').doc('payment_method').set(paymentMethods)
      .then(() => {
        this.isUpdatingPaymentMethods = false; // Parar o estado de carregamento
        Swal.fire({
          title: 'Métodos de pagamento atualizados com sucesso!',
          icon: 'success',
          confirmButtonColor: 'rgb(255,151,0)',
          confirmButtonText: 'OK'
        });
      })
      .catch(error => {
        this.isUpdatingPaymentMethods = false; // Parar o estado de carregamento
        console.error('Erro ao atualizar métodos de pagamento:', error);
        Swal.fire({
          title: 'Erro ao atualizar métodos de pagamento',
          text: error.message || 'Erro desconhecido',
          icon: 'error',
          confirmButtonColor: 'rgb(220, 53, 69)',
          confirmButtonText: 'OK'
        });
      });
  }

  loadDeliveryMethods() {
    this.firestore.collection('config').doc('delivery_methods').get().toPromise().then(doc => {
      if (doc.exists) {
        const data = doc.data() as DeliveryMethods;
        this.delivery = data.delivery;
        this.storePickup = data.store_pickup;
      }
    });
  }

  saveDeliveryMethods() {
    const deliveryMethods: DeliveryMethods = {
      delivery: this.delivery,
      store_pickup: this.storePickup
    };

    this.isUpdatingDeliveryMethods = true; // Iniciar o estado de carregamento
    this.firestore.collection('config').doc('delivery_methods').set(deliveryMethods)
      .then(() => {
        this.isUpdatingDeliveryMethods = false; // Parar o estado de carregamento
        Swal.fire({
          title: 'Métodos de entrega atualizados com sucesso!',
          icon: 'success',
          confirmButtonColor: 'rgb(255,151,0)',
          confirmButtonText: 'OK'
        });
      })
      .catch(error => {
        this.isUpdatingDeliveryMethods = false; // Parar o estado de carregamento
        console.error('Erro ao atualizar métodos de entrega:', error);
        Swal.fire({
          title: 'Erro ao atualizar métodos de entrega',
          text: error.message || 'Erro desconhecido',
          icon: 'error',
          confirmButtonColor: 'rgb(220, 53, 69)',
          confirmButtonText: 'OK'
        });
      });
  }

  toggleMenu() {
    const body = document.getElementsByTagName('body')[0];

    if (body.classList.contains('offcanvas-active')) {
      body.classList.remove('offcanvas-active');
    }
    else {
      body.classList.add('offcanvas-active');
    }
  }

}
