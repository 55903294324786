import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import firebase from 'firebase';
import * as CryptoJS from 'crypto-js';


@Pipe({
  name: 'timestampToDate'
})
export class TimestampToDatePipe implements PipeTransform {
  transform(value: firebase.firestore.Timestamp): Date {
    return value.toDate();
  }
}

@Pipe({
  name: 'titleCase'
})
export class TitleCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    return value.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}

interface Preferences {
  useBiometry: boolean;
  usePin: boolean;
}

interface Cupom {
  id: string;
  code: string;
  timestamp: firebase.firestore.Timestamp;
}

interface User {
  id: string;
  name: string;
  email: string;
  phone: string;
  cpf: string;
  useBiometry: boolean;
  usePin: boolean;
  address: {
    street: string;
    number: string;
    complement: string;
    district: string;
    zipCode: string;
    city: string;
    state: string;
    lat: number;
    long: number;
  };
  createdAt: any;
  settings: {
    preferences: {
      useBiometry: boolean;
      usePin: boolean;
    };
  };
}

interface Order {
  id: string;
  address: {
    street: string;
    number: string;
    complement: string;
    district: string;
    zipCode: string;
    city: string;
    state: string;
    lat: number;
    long: number;
  };
  date: Date;
  delivery: string;
  deliveryPrice: number;
  items: any[];
  price: number;
  user: string;
}

interface Product {
  id: string;
  name: string;
  description: string;
  images: string[];
  reference: string;
  sizes: {
    color: string;
    name: string;
    price: number;
    stock: number;
  }[];
}

@Component({
  selector: 'app-appPortal-clients',
  templateUrl: './appPortal-clients.component.html',
  styleUrls: ['./appPortal-clients.component.scss']
})
export class AppPortalClientsComponent implements OnInit {
  key = CryptoJS.enc.Utf8.parse('Tibet@Online275!'); // Chave de 16 bytes (AES-128)
  iv = CryptoJS.enc.Utf8.parse('1234567890123456'); // IV de 16 bytes

  viewTab = '1';
  addTab = false;
  editTab = false;
  modalViewTab = '1'; // Separate view tab for modal
  clients: User[] = [];
  allClients: User[] = [];
  searchText: string;
  loading = false;
  clienteForm: FormGroup;
  clienteSelecionado: User | null = null;
  historicoPedidos: Order[] = [];
  pedidos: Order[] = [];
  maiorCompra = 0;
  compraMedia = 0;
  ultimaCompra: Date;
  showAllOrders = false;
  cupons: Cupom[] = [];
  showCpf = false;
  visibleCpfs: Set<string> = new Set();

  constructor(private fb: FormBuilder, private firestore: AngularFirestore) {
    this.loadAllClients();
  }

  ngOnInit(): void {
    this.clienteForm = this.fb.group({
      name: [''],
      email: [''],
      phone: [''],
      cpf: [''],
      useBiometry: [false],
      usePin: [false],
      address: this.fb.group({
        street: [''],
        number: [''],
        complement: [''],
        district: [''],
        zipCode: [''],
        city: [''],
        state: [''],
        lat: [0],
        long: [0]
      }),
      settings: this.fb.group({
        preferences: this.fb.group({
          useBiometry: [false],
          usePin: [false]
        })
      })
    });

    this.loadClients();
  }

  decryptAES(encryptedData: string): string {
    if (!encryptedData) {
      // Se o texto criptografado estiver vazio, retorne uma string vazia sem tentar descriptografar
      return '';
    }

    try {
      // Chave e IV devem ser os mesmos usados na criptografia
      const key = CryptoJS.enc.Utf8.parse('Tibet@Online275!'); // Chave de 16 bytes
      const iv = CryptoJS.enc.Utf8.parse('1234567890123456'); // IV de 16 bytes

      // Decodifica o Base64 para bytes
      const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

      // Converte bytes para string
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

      // Se o texto descriptografado for vazio, retorne uma string vazia
      return decryptedText || '';
    } catch (error) {
      // Captura qualquer erro durante o processo de descriptografia e retorna uma string vazia
      console.error('Erro na descriptografia:', error.message);
      return ''; // Mensagem padrão em caso de erro
    }
  }

  loadClients(): void {
    this.loading = true;
    this.firestore.collection('users').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as User;
        const id = a.payload.doc.id;
        return {id, ...data
        };
      }))
    ).subscribe((data: User[]) => {
      this.clients = data;
      // console.log(this.clients);
      this.loading = false;
      // console.log(this.clients);
    }, error => {
      console.error('Error loading clients:', error);
      this.loading = false;
    });
  }

  onTab(number: string): void {
    this.viewTab = number;
  }

  onModalTab(number: string): void {
    this.modalViewTab = number;
    if (number === '2') {
      this.loadCupons();
    }
  }

  editarCliente(cliente: User): void {
    if (cliente) {
      this.clienteSelecionado = cliente;
      this.clienteForm.patchValue(cliente);
      this.viewTab = 'edit';
      this.editTab = true;
    } else {
      console.error('Nenhum cliente selecionado para edição.');
    }
  }

  limparFormulario(): void {
    this.clienteForm.reset();
    this.viewTab = '1';
    this.editTab = false;
  }

  saveProfile(): void {
    const updatedData = this.clienteForm.value;

    if (this.clienteSelecionado) {
      this.loading = true; // Inicia o estado de carregamento
      this.firestore.collection('users').doc(this.clienteSelecionado.id).update(updatedData)
        .then(() => {
          this.loading = false; // Termina o estado de carregamento
          Swal.fire({
            title: 'Perfil atualizado com sucesso!',
            icon: 'success',
            confirmButtonColor: 'rgb(255,151,0)',
            confirmButtonText: 'OK'
          });
          this.limparFormulario();
        })
        .catch(error => {
          this.loading = false; // Termina o estado de carregamento mesmo em caso de erro
          console.error('Erro ao atualizar o perfil:', error);
          Swal.fire({
            title: 'Erro ao atualizar o perfil',
            text: error.message,
            icon: 'error',
            confirmButtonColor: 'rgb(220, 53, 69)',
            confirmButtonText: 'OK'
          });
        });
    }
  }

  loadAllClients(): void {
    this.loading = true;

    this.firestore.collection('users').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as User;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    ).subscribe((data: User[]) => {
      this.allClients = data;
      this.clients = [...data];
      this.loading = false;
    }, error => {
      console.error('Erro ao carregar todos os clientes:', error);
      this.loading = false;
    });
  }

  buscarClientsParam(searchText: string): void {
    this.searchText = searchText;

    if (searchText) {
      this.loading = true;

      this.clients = this.allClients.filter(client =>
        (client.name && client.name.toLowerCase().includes(searchText.toLowerCase())) ||
        (client.cpf && client.cpf.includes(searchText)) ||
        (client.phone && client.phone.includes(searchText))
      );

      if (this.clients.length === 0) {
        this.clienteNaoEncontrado();
      }
      this.loading = false;
    } else {
      this.clients = [...this.allClients];
    }
  }

  limparBusca(): void {
    this.searchText = '';
    this.clients = [...this.allClients];
  }

  async clienteNaoEncontrado(): Promise<void> {
    await Swal.fire({
      title: 'Cliente não encontrado',
      text: 'Nenhum cliente foi encontrado.',
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }

  buscarPedidosUsuario(userId: string): void {
    this.firestore.collection('orders', ref => ref.where('user', '==', userId).orderBy('date', 'desc')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        const date = (data.date && data.date.seconds) ? new Date(data.date.seconds * 1000) : null;
        return {id, ...data, date};
      })),
      switchMap((pedidos: Order[]) => {
        if (pedidos.length === 0) {
          this.pedidos = [];
          this.calcularMaiorCompra();
          this.calcularCompraMedia();
          this.atualizarUltimaCompra();
          this.onOpenModalClick();
          return [];
        }
        const productObservables = pedidos.map(pedido =>
          forkJoin(pedido.items.map(item =>
            this.firestore.collection('products').doc(item.pid).get().pipe(
              map(productDoc => {
                const productData = productDoc.data() as Product;
                return {
                  ...item,
                  productName: productData ? productData.name : 'Produto não encontrado',
                  productDescription: productData ? productData.description : '',
                  productImages: productData ? productData.images : [],
                  productReference: productData ? productData.reference : '',
                };
              })
            )
          ))
        );
        return forkJoin(productObservables).pipe(
          map(itemsArrays => {
            return pedidos.map((pedido, index) => ({
              ...pedido,
              items: itemsArrays[index]
            }));
          })
        );
      })
    ).subscribe(
      (pedidos: Order[]) => {
        this.pedidos = pedidos;
        this.calcularMaiorCompra();
        this.calcularCompraMedia();
        this.atualizarUltimaCompra();
        this.onOpenModalClick();
      },
      (error) => {
        console.error('Erro ao buscar pedidos:', error);
        this.pedidos = [];
        this.onOpenModalClick();
      }
    );
  }

  getInitials(name: string): string {
    if (!name) {
      return '';
    }

    const parts = name.split(' ');
    if (parts.length === 1) {
      return parts[0].charAt(0).toUpperCase();
    }

    const firstInitial = parts[0].charAt(0).toUpperCase();
    const lastInitial = parts[parts.length - 1].charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
  }

  calcularMaiorCompra(): void {
    if (this.pedidos.length > 0) {
      this.maiorCompra = Math.max(...this.pedidos.map(pedido => pedido.price));
    }
  }

  calcularCompraMedia(): void {
    if (this.pedidos.length > 0) {
      const total = this.pedidos.reduce((sum, pedido) => sum + pedido.price, 0);
      this.compraMedia = total / this.pedidos.length;
    }
  }

  buscarConfiguracoesUsuario(userId: string): void {
    this.firestore.collection('users').doc(userId).collection('settings').doc('preferences').get().subscribe(
      (doc) => {
        if (doc.exists) {
          const settings = doc.data() as Preferences;
          this.clienteSelecionado.settings = { preferences: settings };
        } else {
          this.clienteSelecionado.settings = { preferences: { useBiometry: false, usePin: false } };
        }
      },
      (error) => {
        console.error('Erro ao buscar configurações do cliente:', error);
        this.clienteSelecionado.settings = { preferences: { useBiometry: false, usePin: false } };
      }
    );
  }

  buscarHistoricalCompras(clientId: string, cliente: User): void {
    if (cliente) {
      this.clienteSelecionado = cliente;
      this.clienteForm.patchValue(this.clienteSelecionado);
      this.buscarPedidosUsuario(clientId);
      this.buscarConfiguracoesUsuario(clientId);
      this.loadCupons();
    } else {
      console.error('Nenhum cliente selecionado para histórico de compras.');
    }
  }

  onOpenModalClick(): void {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      modal.setAttribute('aria-hidden', 'false');
      modal.removeAttribute('aria-hidden');
      modal.setAttribute('aria-modal', 'true');
      document.body.classList.add('modal-open');
      const backdrop = document.createElement('div');
      backdrop.className = 'modal-backdrop fade show';
      document.body.appendChild(backdrop);
    }
  }

  onCloseModalClick(): void {
    this.limparCampos();
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      modal.setAttribute('aria-hidden', 'true');
      modal.removeAttribute('aria-modal');
      document.body.classList.remove('modal-open');
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.classList.remove('show');
        setTimeout(() => {
          if (backdrop.parentNode) {
            backdrop.parentNode.removeChild(backdrop);
          }
        }, 200);
      }
    }
  }

  limparCampos(): void {
    this.clienteSelecionado = null;
    this.pedidos = [];
    this.maiorCompra = 0;
    this.compraMedia = 0;
    this.ultimaCompra = null;
    this.showAllOrders = false;
  }

  atualizarUltimaCompra(): void {
    if (this.pedidos.length > 0) {
      this.ultimaCompra = this.pedidos[0].date;
    }
  }

  displayValue(value: any, type: string = 'text', isCpf: boolean = false, clientId?: string): any {
    if (isCpf && clientId) {
      // Verifica se o CPF deve ser mostrado ou censurado
      if (this.isCpfVisible(clientId)) {
        return this.decryptAES(value);
      } else {
        return this.censorCpf(value);
      }
    }

    // Lógica para números
    if (type === 'number') {
      return value !== null && value !== undefined ? value : 0;
    }

    // Lógica padrão para textos
    return value ? value : '--';
  }



  loadCupons(): void {
    if (this.clienteSelecionado) {
      this.firestore.collection(`users/${this.clienteSelecionado.id}/used_coupons`).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Cupom;
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).subscribe((data: Cupom[]) => {
        this.cupons = data;
      }, error => {
        console.error('Erro ao carregar cupons:', error);
      });
    }
  }

  excluirCupom(cupomId: string): void {
    if (this.clienteSelecionado) {
      this.firestore.doc(`users/${this.clienteSelecionado.id}/used_coupons/${cupomId}`).delete()
        .then(() => {
          Swal.fire({
            title: 'Cupom excluído com sucesso!',
            icon: 'success',
            confirmButtonColor: 'rgb(255,151,0)',
            confirmButtonText: 'OK'
          });
          this.loadCupons();
        })
        .catch(error => {
          console.error('Erro ao excluir cupom:', error);
        });
    }
  }

  censorCpf(cpf: string): string {
    // Certifica-se de que o CPF é do tipo string e não está vazio
    if (!cpf) {
      return '***.***.***-**';
    }

    // Remove caracteres não numéricos
    const cpfNumbers = cpf.replace(/\D/g, '');

    // Retorna o CPF censurado no formato padrão
    return cpfNumbers.length === 11
      ? `${cpfNumbers.slice(0, 3)}.${cpfNumbers.slice(3, 6)}.${cpfNumbers.slice(6, 9)}-${cpfNumbers.slice(9, 11)}`
      : '***.***.***-**';
  }

  toggleShowCpf(clientId: string) {
    if (this.visibleCpfs.has(clientId)) {
      this.visibleCpfs.delete(clientId);
    } else {
      this.visibleCpfs.add(clientId);
    }
  }

  isCpfVisible(clientId: string): boolean {
    return this.visibleCpfs.has(clientId);
  }
}
