// shared.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Client} from 'src/app/core/_models/clients';
import {PrecoProduto } from 'src/app/core/_models/precoProduto';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  selectedCliente!: Client;
  selectedProduto!: PrecoProduto;
  notificacao = '';
  userFilial = '';
  userFilialID = '';
  tabPreco = '';
  currentUser = '';
  cnpjName = '';
  chavePix = '';
  cidadePix = '';

  constructor() {
  }

  updateFieldsClient() {
    const inputClienteNome = document.getElementById('inputCliente') as HTMLInputElement;
    const inputClienteCpf_Cnpj = document.getElementById('inputCpf') as HTMLInputElement;
    const inputClienteTelefone = document.getElementById('inputTelefone') as HTMLInputElement;
    const inputClienteID = document.getElementById('inputClienteID') as HTMLInputElement;
    if (inputClienteNome && inputClienteCpf_Cnpj && inputClienteTelefone && inputClienteID) {
      inputClienteNome.value = this.selectedCliente.CLIENTE_VAREJO || '';
      inputClienteCpf_Cnpj.value = this.selectedCliente.CPF_CGC || '';
      inputClienteTelefone.value = this.selectedCliente.TELEFONE || '';
      inputClienteID.value = this.selectedCliente.CODIGO_CLIENTE || '';
    }

  }

  updateFieldsProduto() {
    const inputProdutoCodigo = document.getElementById('codigoProduto') as HTMLInputElement;
    const inputProdutoNome = document.getElementById('inputProduto') as HTMLInputElement;
    (document.getElementById('inputQuantidade') as HTMLInputElement).value = '1';
    if (inputProdutoCodigo && inputProdutoNome) {
      inputProdutoCodigo.value = this.selectedProduto?.CODIGO_BARRA || '';
      inputProdutoNome.value = this.selectedProduto?.DESC_PRODUTO || '';
    }
  }

}
