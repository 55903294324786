import { Component, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, first } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LoginService } from '../../../core/services/login.service';
import { UserService } from '../../../core/services/user.service';
import { FirebaseConnectionService } from '../../../core/services/appPortal/firebaseConnection.service';
import { AuthService } from '../../../core/services/appPortal/auth.service';
import { AngularFirestore } from '@angular/fire/firestore'; // Import Firestore

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: true, showIndicators: false } }
  ]
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  showPassword = false;
  isActive: number | boolean = false;
  isDbConnectionSuccessPOS = false;
  isDbConnectionSuccessPortal = false;
  showErrorAlert = false;
  selectedDashboard: string = 'pdv-dashboard';
  userType: string | null = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: LoginService,
    private userService: UserService,
    private firebaseConnectionService: FirebaseConnectionService,
    private firebaseAuthService: AuthService,
    private firestore: AngularFirestore // Inject Firestore
  ) {
    if (this.authenticationService.userValue) {
      this.router.navigate([this.selectedDashboard]);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.userService.setUserType(this.selectedDashboard);

    this.authenticationService.user.subscribe((user) => {
      this.isActive = user?.active || false;
      if (user) {
        this.router.navigateByUrl('/home');
      }
    });

    this.checkAllConnections();
  }

  get f() {
    return this.loginForm.controls;
  }

  checkAllConnections() {
    this.checkDbConnectionPOS();
    this.checkDbConnectionPortal();
  }

  checkDbConnectionPOS() {
    this.authenticationService.checkDbConnection().subscribe(
      () => {
        this.isDbConnectionSuccessPOS = true;
      },
      (error) => {
        this.isDbConnectionSuccessPOS = false;
        if (error.status === 500) {
          this.error = 'Falha na conexão com o banco de dados POS.';
        }
      }
    );
  }

  checkDbConnectionPortal() {
    this.firebaseConnectionService.checkConnection().subscribe(
      (connected) => {
        this.isDbConnectionSuccessPortal = connected;
      },
      (error) => {
        this.isDbConnectionSuccessPortal = false;
      }
    );
  }

  onSubmit() {
    this.error = '';
    this.loading = true;

    if (this.selectedDashboard === 'appPortal-dashboard') {
      this.firebaseAuthService.login(this.f.username.value, this.f.password.value)
        .then(userCredential => {
          const uid = userCredential.user?.uid;
          if (uid) {
            this.firestore.collection('admins').doc(uid).get().subscribe((doc) => {
              if (doc.exists) {
                this.showErrorAlert = false;
                this.setupUserSession();
                this.router.navigateByUrl('/appPortal-dashboard'); // Use a rota correta aqui
              } else {
                this.error = 'Você não tem permissões para entrar';
                this.showErrorAlert = true;
                this.loading = false;
                this.firebaseAuthService.logout(); // Logout the user if not an admin
              }
            });
          } else {
            this.error = 'Usuario ou senha incorreto';
            this.showErrorAlert = true;
            this.loading = false;
          }
        })
        .catch(error => {
          console.error('Login error:', error);
          this.error = 'Usuario ou senha incorreto';
          this.showErrorAlert = true;
          this.loading = false;
        });
    } else {
      this.authenticationService.login(this.f.username.value, this.f.password.value)
        .pipe(
          first(),
          catchError(error => {
            console.error('Error occurred:', error);
            this.error = 'Usuario ou senha incorreto';
            this.showErrorAlert = true;
            this.loading = false;
            setTimeout(() => {
              this.showErrorAlert = false;
            }, 5000);
            return throwError(error);
          })
        )
        .subscribe({
          next: (user) => {
            this.showErrorAlert = false;
            this.setupUserSession();
            this.router.navigateByUrl('/pdv-dashboard');
          },
          error: error => {
            console.error('Login error:', error);
            this.error = 'Usuario ou senha incorreto';
            this.showErrorAlert = true;
            this.loading = false;
          }
        });
    }
  }

  setupUserSession() {
    console.log('Setting up user session');
    sessionStorage.setItem('HeaderClass', 'top_dark');
    sessionStorage.setItem('MenuIcon', 'list-a');
    sessionStorage.setItem('Toggle8', 'true');
    sessionStorage.setItem('Font1', 'font-montserrat');
    sessionStorage.setItem('Toggle3', 'true');
    sessionStorage.setItem('BoxShadow', 'box_shadow');
    sessionStorage.setItem('notificationUnRead', 'true');
    this.userType = this.selectedDashboard;
    this.userService.setUserType(this.userType);
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}
