// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'http://192.168.15.176:8000',
  accessToken: 'APP_USR-5052253783798702-080214-ddd8eb234a1e69bc638e95f61947d890-146511220',
  firebaseConfig: {
    apiKey: 'AIzaSyCL6r2YPW5rmiQBYiXsSXGUaBddM1q8lwA',
    authDomain: 'loja-virtual-tibet.firebaseapp.com',
    projectId: 'loja-virtual-tibet',
    storageBucket: 'loja-virtual-tibet.appspot.com',
    messagingSenderId: '544378988266',
    appId: '1:544378988266:web:e2f07a2cb07e9cf58e1993',
    measurementId: 'G-WVY41EJ3X1'
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
