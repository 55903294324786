import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import Swal from 'sweetalert2';
import {EstoqueService} from '../../../../core/services/pdv/estoque.service';
import {EstoqueProduto} from '../../../../core/_models/estoque';
import {FilialService} from '../../../../core/services/pdv/filiais.service';
import {Filial} from '../../../../core/_models/filial';

interface ProductResponse {
  [key: string]: EstoqueProduto[];
}

@Pipe({ name: 'filterByFirstLetter' })
export class FilterByFirstLetterPipe implements PipeTransform {
  transform(value: string[]): string[] {
    return value.filter(tamanho => tamanho.match(/[A-Za-z]/));
  }
}

@Component({
  selector: 'app-appPortal-estoque',
  templateUrl: './pdv-estoque.component.html',
  styleUrls: ['./pdv-estoque.component.scss']
})

export class PdvEstoqueComponent implements OnInit {

constructor(private estoqueService: EstoqueService, private filialService: FilialService) { }

  viewTab = true;
  addTab = false;
  editTab = false;
  search = '';
  codigoProduto = '';
  selectedCor = 'Selecione a cor';
  selectedTamanho = 'Selecione o tamanho';
  protected filiais: Filial[];
  selectedFilial = 'all';
  protected coresDisponiveis: any[];
  protected tamanhosDisponiveis: any[];
  produtos: EstoqueProduto[] = [];
  protected produtoSelecionado: any[];
  protected loading: boolean;

  protected readonly Object = Object;

  ngOnInit(): void {
    this.carregarFiliais();
  }


  carregarFiliais(): void {
    let filialFilter: string | null = null;

    if (this.selectedFilial !== 'all') {
      filialFilter = this.selectedFilial.toString();
    }

    this.filialService.buscarTodasFiliais().subscribe(
      filiais => {
        this.filiais = filiais;
      },
      error => {
        console.log('Ocorreu um erro ao carregar as filiais:', error);
      }
    );
  }

  // tslint:disable-next-line:variable-name
  onTab(number: string): void {
    this.viewTab = false;
    this.addTab = false;
    this.editTab = false;
    if (number === '1') {
      this.viewTab = true;
    } else if (number === '2') {
      this.addTab = true;
    } else if (number === '3') {
      this.editTab = true;
    }
  }

  async produtoNaoEncontrado(): Promise<void> {
    await Swal.fire({
      title: 'Produto não encontrado',
      text: 'Nenhum produto foi encontrado.',
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }


  buscarProdutos(): void {
    this.loading = true;
    // Acessar o elemento HTML do input do código do produto
    const codigoInput: HTMLInputElement | null = document.querySelector('#codigoInput');

    if (codigoInput) {
      // Atribuir o valor do input como uma string à variável codigoProduto
      this.codigoProduto = codigoInput.value.trim();

      // Chame o serviço para buscar os produtos com base na filial e no código do produto
      this.estoqueService.buscarPorFilialEProduto(this.selectedFilial, this.codigoProduto).subscribe(
        (response: ProductResponse | EstoqueProduto[]) => {

          console.log(this.selectedFilial);
          console.log(this.codigoProduto);

          console.log(response);
          // Verificar se a resposta é um objeto e se contém produtos
          if (response && typeof response === 'object' && Object.keys(response).length > 0) {
            // Produtos encontrados, continue como antes
            if (Array.isArray(response)) {
              this.produtos = response as EstoqueProduto[];
              this.loading = false;
            } else {
              this.produtos = Object.values(response).reduce((acc: EstoqueProduto[], val: EstoqueProduto[]) => acc.concat(val), []);
              this.loading = false;
            }
            this.coresDisponiveis = this.extrairCoresDisponiveis(this.produtos);
            this.tamanhosDisponiveis = this.extrairTamanhosDisponiveis(this.produtos);
            this.onOpenModalClick();
            this.loading = false;
          } else {
            // Nenhum produto encontrado, exiba um aviso e não abra o modal
            this.produtoNaoEncontrado();
            this.produtos = [];
            this.loading = false;
          }
        },
        (error) => {
          console.error('Erro ao buscar produtos:', error);
          this.loading = false;
        }
      );
      this.loading = false;
    } else {
      console.error('Elemento de input do código do produto não encontrado');
      this.loading = false;
    }
  }



  async confirmarExclusao(): Promise<void> {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      Swal.fire(
        'Deleted!',
        'User has been deleted.',
        'success'
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        '',
        'error'
      );
    }
  }

  isNumero(value: any): boolean {
    return !isNaN(value) && typeof value === 'number';
  }

  onOpenModalClick(): void {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  }

  onCloseModalClick(): void {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
  }

  filtrarProdutos(): EstoqueProduto[] {
    if (!this.selectedCor && !this.selectedTamanho) {
      return this.produtos;
    }

    const produtosFiltrados = this.produtos.filter(produto => {
      this.loading = true;
      // Verificar se a cor do produto corresponde à cor selecionada
      const corCorrespondente = !this.selectedCor || produto.COR_PRODUTO === this.selectedCor;

      // Verificar se o tamanho selecionado está presente nos tamanhos do produto e se a quantidade disponível é maior que zero
      const tamanhoCorrespondente = !this.selectedTamanho || (produto.tamanhos && produto.tamanhos[this.selectedTamanho.trim()] > 0);

      this.loading = false;
      return corCorrespondente && tamanhoCorrespondente;
    });
    return produtosFiltrados;
  }



  // Método para extrair cores disponíveis dos produtos filtrados
  extrairCoresDisponiveis(produtosFiltrados: EstoqueProduto[]): string[] {
    const coresDisponiveis: string[] = [];

    // Iterar sobre os produtos filtrados
    produtosFiltrados.forEach(produto => {
      // Remover espaços em branco extras e adicionar a cor à lista de cores disponíveis, se ainda não estiver presente
      const corLimpa = produto.COR_PRODUTO.trim();
      if (!coresDisponiveis.includes(corLimpa)) {
        coresDisponiveis.push(corLimpa);
      }
    });

    return coresDisponiveis;
  }


  // Método para extrair tamanhos disponíveis dos produtos filtrados
  extrairTamanhosDisponiveis(produtosFiltrados: EstoqueProduto[]): string[] {
    let tamanhosDisponiveis: string[] = [];

    // Iterar sobre os produtos filtrados
    produtosFiltrados.forEach(produto => {
      // Iterar sobre as chaves (nomes de tamanhos) do objeto de tamanhos do produto
      Object.keys(produto.tamanhos).forEach(tamanho => {
        // Adicionar o tamanho à lista de tamanhos disponíveis, se a quantidade for maior que zero
        if (produto.tamanhos[tamanho] > 0) {
          tamanhosDisponiveis.push(tamanho);
        }
      });
    });

    // Filtrar apenas tamanhos únicos
    tamanhosDisponiveis = Array.from(new Set(tamanhosDisponiveis));

    return tamanhosDisponiveis;
  }

  nenhumProdutoEncontrado(): boolean {
    const produtosFiltrados = this.filtrarProdutos();
    const estoqueTotal = produtosFiltrados.reduce((total, produto) => total + (produto.ESTOQUE || 0), 0);
    return produtosFiltrados.length === 0 || estoqueTotal === 0;
  }






}
