import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase';
import {Coupon} from '../../_models/appPortal/coupons';

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  constructor(private firestore: AngularFirestore) {}

  getCoupons(): Observable<Coupon[]> {
    return this.firestore.collection('coupons').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Coupon;
        const uid = a.payload.doc.id;
        return { uid, ...data };
      }))
    );
  }

  updateCoupon(coupon: Coupon): Promise<void> {
    return this.firestore.collection('coupons').doc(coupon.uid).update(coupon);
  }

  addCoupon(coupon: Coupon): Promise<firebase.firestore.DocumentReference> {
    return this.firestore.collection('coupons').add(coupon);
  }
}
