import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Client } from '../../_models/clients';
import {EstoqueProduto} from '../../_models/estoque';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  private baseUrl = `${environment.apiUrl}/clients`;

  constructor(private http: HttpClient) { }

  // Método para buscar todos os clients
  buscarTodosClients(): Observable<Client[]> {
    return this.http.get<Client[]>(`${this.baseUrl}`).pipe(
      catchError(error => {
        console.error('Ocorreu um erro ao buscar os clientes:', error);
        return throwError(error);
      })
    );
  }


  // Método para buscar clients
  buscarClientsByParam(clientParam: string): Observable<Client[]> {
    return this.http.get<Client[]>(`${this.baseUrl}/search?clientParam=${clientParam}`).pipe(
      catchError(error => {
        console.error('Ocorreu um erro ao buscar os clientes:', error);
        return throwError(error);
      })
    );
  }

  // Método para buscar ultimo IDclient do FlexDuck
  buscarUltimoIdClients(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/getLastId`).pipe(
      catchError(error => {
        console.error('Ocorreu um erro ao buscar o último ID:', error);
        return throwError(error);
      })
    );
  }

  buscarHistoricoCompras(clientParam: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/getHistoricPurchase?clientParam=${clientParam}`).pipe(
      catchError(error => {
        console.error('Ocorreu um erro ao buscar os clientes:', error);
        return throwError(error);
      })
    );
  }

  adicionarCliente(cliente: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/addCliente`, cliente);
  }


}
