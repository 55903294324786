import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { MercadoPagoService } from '../../../../core/services/appPortal/mercadopago.service';
import { FirebaseUserService } from '../../../../core/services/appPortal/firebaseUser.service';
import { PedidoService } from '../../../../core/services/appPortal/pedidos.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Order } from '../../../../core/_models/appPortal/order';

@Pipe({
  name: 'timestampToDateHour'
})
export class TimestampToDateHourPipe implements PipeTransform {
  transform(timestamp: any, format: string = 'dd/MM/yyyy HH:mm'): string | null {
    if (!timestamp) {
      return null;
    }
    const date = timestamp.seconds ? new Date(timestamp.seconds * 1000) : new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };

    return new Intl.DateTimeFormat('pt-BR', options).format(date);
  }
}

const orderStatus = new Map([
  [0, 'Cancelado'],
  [1, 'Em Preparação'],
  [2, 'Em Transporte'],
  [3, 'Aguardando Retirada'],
  [4, 'Entregue']
]);

const statusBadgeClass = new Map([
  [0, 'badge-danger'],
  [1, 'badge-warning'],
  [2, 'badge-info'],
  [3, 'badge-primary'],
  [4, 'badge-success']
]);

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-appPortal-report',
  templateUrl: './appPortal-report.component.html',
  styleUrls: ['./appPortal-report.component.scss']
})
export class AppPortalReportComponent implements OnInit {
  constructor(private mercadoPagoService: MercadoPagoService,
              private firebaseUserService: FirebaseUserService,
              private pedidoService: PedidoService) { }
  invoiceTab = true;
  paymentTab: boolean;
  expencesTab: boolean;
  mercadoPagoTab: boolean;
  payments: any[] = [];
  paginatedPayments: any[] = [];
  pedidos: Order[] = [];
  filteredPedidos: Order[] = [];
  pedidoSelecionado: Order | null = null;
  page = 1;
  pageSize = 20;
  totalSold = 0;
  totalFees = 0;
  totalPayments = 0;
  limit = 30; // Limite padrão de resultados da API
  offset = 0; // Deslocamento inicial
  loading = false; // Flag para mostrar o estado de carregamento
  pedidosCache: Map<string, Order> = new Map(); // Cache para pedidos
  totalDelivery = 0;

  salesVolumeData: { date: string, amount: number, units: number }[] = []; // Dados para o gráfico
  chartMode: 'value' | 'units' = 'value'; // Modo do gráfico
  uniqueUsers = 0;
  uniqueOrders = 0;

  paymentStatusMessages = new Map([
    ['pending', 'Pendente'],
    ['approved', 'Aprovado'],
    ['authorized', 'Autorizado'],
    ['in_process', 'Processando'],
    ['in_mediation', 'Em Disputa'],
    ['rejected', 'Rejeitado'],
    ['cancelled', 'Cancelado'],
    ['refunded', 'Reembolsado'],
    ['charged_back', 'Estornado'],
    ['failed', 'Falhou'],
    ['unknown', 'Erro']
  ]);

  protected readonly Math = Math;

  ngOnInit(): void {
    this.onTab('4');
    this.loadUserData();
    this.loadOrderData();
    this.loadPedidos();
  }

  // tslint:disable-next-line:variable-name
  onTab(number: string): void {
    this.invoiceTab = false;
    this.paymentTab = false;
    this.expencesTab = false;
    this.mercadoPagoTab = false;

    if (number === '1') {
      this.invoiceTab = true;
    } else if (number === '2') {
      this.paymentTab = true;
    } else if (number === '3') {
      this.expencesTab = true;
    } else if (number === '4') {
      this.mercadoPagoTab = true;
      this.page = 1; // Reinicia a página
      this.offset = 0; // Reinicia o offset
      this.payments = []; // Limpa os pagamentos
      this.salesVolumeData = []; // Limpa os dados de volume de vendas
      this.loadPayments(0); // Carrega os pagamentos iniciais
    }
  }

  loadUserData(): void {
    this.firebaseUserService.countUsers().subscribe(count => {
      this.uniqueUsers = count;
    });
  }

  loadOrderData(): void {
    this.pedidoService.countOrders().subscribe(count => {
      this.uniqueOrders = count;
    });
  }

  loadPayments(offset: number): void {
    if (this.loading) { return; }
    this.loading = true;
    this.mercadoPagoService.getPayments(this.pageSize, offset).subscribe(
      (response: any) => {
        console.log('API Response:', response);
        if (response && response.results) {
          // Filtra os pagamentos com base na descrição
          const newPayments = response.results
            .filter(payment =>
              payment.description?.startsWith('Order') || payment.description?.startsWith('Tibet Online')
            )
            .map(payment => ({
              description: payment.description || 'N/A',
              card: payment.card || { cardholder: { name: 'N/A' } },
              payer: payment.payer || { email: 'N/A' },
              date_created: payment.date_created,
              date_last_updated: payment.date_last_updated || 'N/A',
              date_approved: payment.date_approved || 'N/A',
              status: payment.status,
              payment_method_id: payment.payment_method_id,
              transaction_amount: payment.transaction_amount,
              fee: payment.fee_details?.reduce((acc, fee) => acc + fee.amount, 0) || 0
            }));
          this.paginatedPayments = newPayments; // Atualize os pagamentos paginados
          this.calculateTotals();
          this.totalPayments = response.paging.total;
          this.prepareSalesVolumeData();
        }
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching payments:', error);
        this.loading = false;
      }
    );
  }

  calculateTotals(): void {
    // Filtra apenas os pagamentos aprovados
    const approvedPayments = this.paginatedPayments.filter(payment => payment.status === 'approved');

    // Calcula os totais apenas para os pagamentos aprovados
    this.totalSold = approvedPayments.reduce((acc, payment) => acc + payment.transaction_amount, 0);
    this.totalFees = approvedPayments.reduce((acc, payment) => acc + payment.fee, 0);

    this.prepareSalesVolumeData();
  }


  getFeesPercentage(): number {
    return this.totalSold > 0 ? (this.totalFees / this.totalSold) * 100 : 0;
  }

  updatePagination(): void {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedPayments = this.payments.slice(start, end);
  }

  onPageChange(page: number): void {
    if (page > 0 && page <= Math.ceil(this.totalPayments / this.pageSize)) {
      this.page = page;
      const offset = (page - 1) * this.pageSize;
      this.loadPayments(offset);
    }
  }

  getPaidByIcon(paymentMethodId: string): string {
    switch (paymentMethodId) {
      case 'master':
        return 'payment payment-mastercard';
      case 'debmaster':
        return 'payment payment-mastercard';
      case 'visa':
        return 'payment payment-visa';
      case 'debvisa':
        return 'payment payment-visa';
      case 'account_money':
        return 'payment payment-mercadolibre';
      case 'paypal':
        return 'payment payment-paypal';
      case 'pix':
        return 'payment-pix';
      default:
        return 'payment payment-default';
    }
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'approved':
        return 'badge badge-success';
      case 'pending':
        return 'badge badge-warning';
      case 'cancelled':
        return 'badge badge-danger';
      default:
        return 'badge badge-default';
    }
  }

  prepareSalesVolumeData(): void {
    const salesVolumeDataMap = new Map<string, { amount: number, units: number }>();

    this.paginatedPayments.forEach(payment => { // Usar paginatedPayments em vez de payments
      const date = payment.date_created ? payment.date_created.split('T')[0] : 'N/A'; // Considera apenas a data sem a hora
      if (!salesVolumeDataMap.has(date)) {
        salesVolumeDataMap.set(date, { amount: 0, units: 0 });
      }
      const salesData = salesVolumeDataMap.get(date);
      if (salesData) {
        salesData.amount += payment.transaction_amount || 0;
        salesData.units += 1;
      }
    });

    this.salesVolumeData = Array.from(salesVolumeDataMap, ([date, data]) => ({
      date,
      amount: Number(data.amount.toFixed(2)),
      units: data.units
    })).filter(data => data.date !== 'N/A');
  }


  toggleChartMode(): void {
    this.chartMode = this.chartMode === 'value' ? 'units' : 'value';
  }

  loadPedidos(): void {
    this.loading = true;
    this.pedidoService.getPedidos().pipe(
      switchMap((pedidos: Order[]) => {
        const pedidosComNomes = pedidos.map(pedido => {
          const clienteNome$ = this.pedidoService.getClienteNome(pedido.user);
          const itensDetalhados$ = forkJoin(
            pedido.items.map(item => this.pedidoService.getProductDetails(item.pid).pipe(
              map(details => ({
                ...item,
                productName: details.name,
                productReference: details.reference,
                productImage: details.image
              }))
            ))
          );

          return forkJoin([clienteNome$, itensDetalhados$]).pipe(
            map(([clienteNome, itensDetalhados]) => ({
              ...pedido,
              cliente: clienteNome,
              items: itensDetalhados,
              statusText: orderStatus.get(pedido.status) || 'Desconhecido',
              statusBadgeClass: statusBadgeClass.get(pedido.status) || 'badge-secondary'
            }))
          );
        });
        return forkJoin(pedidosComNomes);
      })
    ).subscribe(
      (pedidos: any[]) => {
        this.pedidos = pedidos.sort((a, b) => b.date - a.date); // Ordenar por data decrescente
        this.filteredPedidos = [...this.pedidos];
        this.pedidos.forEach(pedido => this.pedidosCache.set(pedido.id, pedido)); // Armazena no cache
        this.loading = false;
      },
      (error) => {
        console.error('Erro ao carregar pedidos:', error);
        this.loading = false;
      }
    );
  }

  verDetalhesPedido(description: string): void {
    const orderNumberMatch = description.match(/#(\d+)/);
    if (orderNumberMatch && orderNumberMatch[1]) {
      const orderId = orderNumberMatch[1].trim();
      const cachedPedido = this.pedidosCache.get(orderId); // Verifica o cache

      if (cachedPedido) {
        this.pedidoSelecionado = cachedPedido;
        this.showModal();
      } else {
        this.pedidoService.getPedidoById(orderId).subscribe(pedido => {
          this.pedidoSelecionado = pedido || null;
          if (this.pedidoSelecionado) {
            this.pedidosCache.set(orderId, this.pedidoSelecionado); // Armazena no cache
          }
          this.showModal();
        });
      }
    }
  }

  showModal(): void {
    const modal = document.getElementById('detalhesPedidoModal');
    if (modal && this.pedidoSelecionado) {
      modal.classList.add('show');
      modal.style.display = 'block';
      modal.setAttribute('aria-hidden', 'false');
      modal.removeAttribute('aria-hidden');
      modal.setAttribute('aria-modal', 'true');
      document.body.classList.add('modal-open');
      const backdrop = document.createElement('div');
      backdrop.className = 'modal-backdrop fade show';
      document.body.appendChild(backdrop);
    }
  }

  fecharModal(): void {
    const modal = document.getElementById('detalhesPedidoModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      modal.setAttribute('aria-hidden', 'true');
      modal.removeAttribute('aria-modal');
      document.body.classList.remove('modal-open');
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    }
  }

  fullScreenSection(sectionId: string): void {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      if (sectionElement.classList.contains('fullscreen')) {
        sectionElement.classList.remove('fullscreen');
      } else {
        sectionElement.classList.add('fullscreen');
      }
    }
  }

  downloadPDF(): void {
    const doc = new jsPDF();

    // Títulos das colunas
    const col = ['Descrição', 'Portador do Cartão', 'Email do Pagador', 'Data de Criação', 'Data de Aprovação', 'Status', 'Valor'];

    // Linhas de dados
    const rows = this.payments.map(payment => [
      payment.description || 'N/A',
      payment.card?.cardholder?.name || 'N/A',
      payment.payer?.email || 'N/A',
      payment.date_created ? new Date(payment.date_created).toLocaleDateString() : 'N/A',
      payment.date_approved ? new Date(payment.date_approved).toLocaleDateString() : 'N/A',
      payment.status || 'N/A',
      payment.transaction_amount ? payment.transaction_amount.toFixed(2) : 'N/A'
    ]);

    // Adiciona a tabela ao PDF com autoTable
    (doc as any).autoTable({
      head: [col],
      body: rows,
      startY: 10,
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      styles: {
        fontSize: 8,
        cellPadding: 3,
        overflow: 'linebreak',
      },
      bodyStyles: { valign: 'top' },
      theme: 'striped',
      showHead: 'everyPage'
    });

    // Salva o PDF
    doc.save('payments.pdf');
  }

  downloadCSV(): void {
    const rows = this.payments.map(payment => ({
      Descrição: payment.description || 'N/A',
      'Portador do Cartão': payment.card?.cardholder?.name || 'N/A',
      'Email do Pagador': payment.payer?.email || 'N/A',
      'Data de Criação': payment.date_created ? new Date(payment.date_created).toLocaleDateString() : 'N/A',
      'Data de Aprovação': payment.date_approved ? new Date(payment.date_approved).toLocaleDateString() : 'N/A',
      Status: payment.status || 'N/A',
      Valor: payment.transaction_amount ? payment.transaction_amount.toFixed(2) : 'N/A'
    }));

    const csvContent = this.convertToCSV(rows);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'payments.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  convertToCSV(objArray: any[]): string {
    const header = Object.keys(objArray[0]).join(',');
    const rows = objArray.map(row => Object.values(row).join(','));
    return [header, ...rows].join('\r\n');
  }

  getPayerOrCardholderName(payment: any): string {
    const payerName = `${payment.payer?.first_name || ''} ${payment.payer?.last_name || ''}`.trim();
    const cardholderName = payment.card?.cardholder?.name || 'N/A';
    const finalName = payerName || cardholderName;
    return this.capitalizeWords(finalName);
  }

  capitalizeWords(name: string): string {
    return name.replace(/\b\w/g, char => char.toUpperCase());
  }

  // Função para obter o rótulo do status
  getPaymentStatusLabel(status: string): string {
    return this.paymentStatusMessages.get(status) || this.paymentStatusMessages.get('unknown');
  }
}
