import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Coupon } from '../../../../core/_models/appPortal/coupons';
import { CouponService } from '../../../../core/services/appPortal/coupons.service';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaToDot'
})
export class CommaToDotPipe implements PipeTransform {
  transform(value: any): any {
    if (typeof value === 'string') {
      return value.replace(/,/g, '.');
    }
    return value;
  }
}

@Component({
  selector: 'app-appPortal-preco',
  templateUrl: './appPortal-preco.component.html',
  styleUrls: ['./appPortal-preco.component.scss']
})
export class AppPortalPrecoComponent implements OnInit {
  coupons: Coupon[] = [];
  filteredCoupons: Coupon[] = [];
  loading = false;
  searchText = '';
  couponForm: FormGroup;
  editMode = false;
  showAdvancedOptions = false;
  selectedCoupon: Coupon | null = null;
  isFreeShipping = false;
  isSaving = false;

  constructor(private couponService: CouponService, private fb: FormBuilder) {
    this.couponForm = this.fb.group({
      uid: [''],  // Corrigir para usar 'uid' ao invés de 'id'
      code: ['', Validators.required],
      name: ['', Validators.required],
      discountValue: [0, Validators.required],
      isPercentage: [false],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      quantity: [0, Validators.required],
      minOrderValue: [0],
      maxOrderValue: [0],
      maxDiscountValue: [0],
      accountAge: [0]
    });
  }

  ngOnInit(): void {
    this.loadCoupons();
  }

  loadCoupons(): void {
    this.loading = true;
    this.couponService.getCoupons().subscribe(
      (coupons) => {
        this.coupons = coupons;
        this.filteredCoupons = coupons;
        this.loading = false;
      },
      (error) => {
        console.error('Erro ao carregar os cupons:', error);
        this.loading = false;
      }
    );
  }

  buscarCupons(searchText: string): void {
    if (searchText) {
      this.loading = true;
      this.filteredCoupons = this.coupons.filter(coupon =>
        (coupon.name && coupon.name.toLowerCase().includes(searchText.toLowerCase())) ||
        (coupon.code && coupon.code.toLowerCase().includes(searchText.toLowerCase()))
      );

      if (this.filteredCoupons.length === 0) {
        this.cupomNaoEncontrado();
      }
      this.loading = false;
    } else {
      this.filteredCoupons = [...this.coupons];
    }
  }

  limparBusca(): void {
    this.searchText = '';
    this.filteredCoupons = [...this.coupons];
  }

  async cupomNaoEncontrado(): Promise<void> {
    await Swal.fire({
      title: 'Cupom não encontrado',
      text: 'Nenhum cupom foi encontrado.',
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }

  openCouponModal(coupon: Coupon | null): void {
    this.editMode = !!coupon;
    if (coupon) {
      console.log('UID do documento do Firestore:', coupon.uid);  // ID correto

      this.couponForm.patchValue({
        uid: coupon.uid,
        code: coupon.code,
        name: coupon.name,
        discountValue: coupon.discountValue,
        isPercentage: coupon.isPercentage,
        startDate: this.formatDate(coupon.startDate.toDate()),
        endDate: this.formatDate(coupon.endDate.toDate()),
        quantity: coupon.quantity,
        minOrderValue: coupon.minOrderValue,
        maxOrderValue: coupon.maxOrderValue,
        maxDiscountValue: coupon.maxDiscountValue,
        accountAge: coupon.accountAge
      });

      this.isFreeShipping = coupon.discountValue === 0 && !coupon.isPercentage;
      this.toggleFreeShipping();
    } else {
      this.couponForm.reset();
      this.isFreeShipping = false;
      this.toggleFreeShipping();
    }
    this.showModal('couponModal');
  }

  openViewModal(coupon: Coupon): void {
    this.selectedCoupon = coupon;
    this.showModal('viewCouponModal');
  }

  onCloseModalClick(): void {
    this.hideModal('couponModal');
    this.hideModal('viewCouponModal');
  }

  saveCoupon(): void {
    if (this.couponForm.invalid) {
      Swal.fire('Erro', 'Preencha todos os campos obrigatórios.', 'error');
      return;
    }

    this.isSaving = true;
    const couponData = { ...this.couponForm.value };

    // Conversão de valores e verificação de nullabilidade
    couponData.discountValue = parseFloat(couponData.discountValue) || 0;
    couponData.minOrderValue = couponData.minOrderValue ? parseFloat(couponData.minOrderValue) : null;
    couponData.maxOrderValue = couponData.maxOrderValue ? parseFloat(couponData.maxOrderValue) : null;
    couponData.maxDiscountValue = couponData.maxDiscountValue ? parseFloat(couponData.maxDiscountValue) : null;
    couponData.isPercentage = !!couponData.isPercentage;

    // Conversão de strings de data para objetos de data
    couponData.startDate = new Date(couponData.startDate);
    couponData.endDate = new Date(couponData.endDate);

    console.log('Coupon data before save:', couponData);

    if (this.editMode) {
      // Atualizar o cupom existente
      if (!couponData.uid) {
        Swal.fire('Erro', 'UID do cupom não pode ser vazio para atualização.', 'error');
        this.isSaving = false;
        return;
      }

      this.couponService.updateCoupon(couponData).then(() => {
        Swal.fire('Sucesso', 'Cupom atualizado com sucesso.', 'success');
        this.loadCoupons();
        this.onCloseModalClick();
      }).catch((error) => {
        Swal.fire('Erro', 'Erro ao atualizar o cupom: ' + error, 'error');
      }).finally(() => {
        this.isSaving = false;
      });
    } else {
      // Adicionar um novo cupom
      this.couponService.addCoupon(couponData).then((docRef) => {
        // Captura o UID gerado pelo Firebase
        const newCouponUid = docRef.id;

        // Atualize o formulário com o UID do novo cupom
        this.couponForm.patchValue({ uid: newCouponUid });

        Swal.fire('Sucesso', 'Cupom adicionado com sucesso.', 'success');
        this.loadCoupons();
        this.onCloseModalClick();
      }).catch((error) => {
        Swal.fire('Erro', 'Erro ao adicionar o cupom: ' + error, 'error');
      }).finally(() => {
        this.isSaving = false;
      });
    }
  }

  isCouponExpired(coupon: Coupon): boolean {
    return coupon.endDate.toDate().getTime() < Date.now();
  }

  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  private showModal(id: string): void {
    const modal = document.getElementById(id);
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  }

  private hideModal(id: string): void {
    const modal = document.getElementById(id);
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
  }

  copyToClipboard(code: string): void {
    navigator.clipboard.writeText(code).then(() => {
      Swal.fire('Copiado!', 'Código copiado para a área de transferência.', 'success');
    }).catch(err => {
      Swal.fire('Erro!', 'Erro ao copiar o código: ' + err, 'error');
    });
  }

  toggleAdvancedOptions(): void {
    this.showAdvancedOptions = !this.showAdvancedOptions;
  }

  setFreteGratis(): void {
    this.isFreeShipping = !this.isFreeShipping;
    this.toggleFreeShipping();
  }

  toggleFreeShipping(): void {
    if (this.isFreeShipping) {
      this.couponForm.patchValue({
        discountValue: 0,
        isPercentage: false,
        maxDiscountValue: null,
      });
      this.couponForm.get('discountValue').disable();
      this.couponForm.get('isPercentage').disable();
      this.couponForm.get('maxDiscountValue').disable();
    } else {
      this.couponForm.get('discountValue').enable();
      this.couponForm.get('isPercentage').enable();
      this.couponForm.get('maxDiscountValue').enable();
    }
  }
}
