import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

export interface TokenData {
  cnpj: string;
  name: string;
  startIn: Date;
  expiresIn: Date;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  // Método para decodificar e extrair informações do token JWT
  decryptAndExtractToken(token: string): TokenData | null {
    try {
      // Decodificar o token JWT
      const decodedToken: any = jwt_decode(token);

      // Verificar se o token foi decodificado corretamente
      if (!decodedToken) {
        throw new Error('Erro ao decodificar a key');
      }

      // Converter os timestamps em segundos para objetos de data JavaScript
      const startInDate = new Date(decodedToken.startIn * 1000);
      const expiresInDate = new Date(decodedToken.expiresIn * 1000);

      // Retornar as informações do token decodificado
      return {
        cnpj: decodedToken.cnpj,
        name: decodedToken.name,
        startIn: startInDate,
        expiresIn: expiresInDate
      };
    } catch (error) {
      return null;
    }
  }

  // Método para decodificar o token JWT armazenado no localStorage
  decodeTokenFromLocalStorage(): any {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      return jwt_decode(accessToken);
    } else {
      return null;
    }
  }

}
