import {Component, NgIterable, OnInit, Pipe, PipeTransform} from '@angular/core';
import Swal from 'sweetalert2';
import { EstoqueService } from '../../../../core/services/pdv/estoque.service';
import { EstoqueProduto } from '../../../../core/_models/estoque';
import {FilialService} from '../../../../core/services/pdv/filiais.service';
import {Filial} from '../../../../core/_models/filial';
import {PrecoProduto} from '../../../../core/_models/precoProduto';

interface ProductResponse {
  [key: string]: EstoqueProduto[];
}

@Pipe({ name: 'filterByFirstLetter' })
export class FilterByFirstLetterPipe implements PipeTransform {
  transform(value: string[]): string[] {
    return value.filter(tamanho => tamanho.match(/[A-Za-z]/));
  }
}

@Component({
  selector: 'app-appPortal-preco',
  templateUrl: './pdv-preco.component.html',
  styleUrls: ['./pdv-preco.component.scss']
})

export class PdvPrecoComponent implements OnInit {

constructor(private estoqueService: EstoqueService, private filialService: FilialService) { }

  viewTab = true;
  addTab = false;
  editTab = false;
  search = '';
  codigoProduto = '';
  selectedCor = 'Selecione a cor';
  selectedTamanho = 'Selecione o tamanho';
  protected filiais: Filial[];
  selectedFilial = 'all';
  protected coresDisponiveis: any[];
  protected tamanhosDisponiveis: Set<string>;
  produtos: PrecoProduto[] = [];
  protected produtoSelecionado: any[];
  protected loading: boolean;

  protected readonly Object = Object;

  ngOnInit(): void {
    this.carregarFiliais();
  }


  carregarFiliais(): void {
    let filialFilter: string | null = null;

    if (this.selectedFilial !== 'all') {
      filialFilter = this.selectedFilial.toString();
    }

    this.filialService.buscarTodasFiliais().subscribe(
      filiais => {
        this.filiais = filiais;
      },
      error => {
        console.log('Ocorreu um erro ao carregar as filiais:', error);
      }
    );
  }

  // tslint:disable-next-line:variable-name
  onTab(number: string): void {
    this.viewTab = false;
    this.addTab = false;
    this.editTab = false;
    if (number === '1') {
      this.viewTab = true;
    } else if (number === '2') {
      this.addTab = true;
    } else if (number === '3') {
      this.editTab = true;
    }
  }

  async produtoNaoEncontrado(): Promise<void> {
    await Swal.fire({
      title: 'Produto não encontrado',
      text: 'Nenhum produto foi encontrado .',
      icon: 'error',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'OK'
    });
  }


  buscarProdutos(): void {
    this.loading = true;
    // Acessar o elemento HTML do input do código do produto
    const codigoInput: HTMLInputElement | null = document.querySelector('#codigoInput');

    if (!codigoInput) {
      this.loading = false;
      console.error('Elemento de input do código do produto não encontrado');
      return;
    }

    // Atribuir o valor do input como uma string à variável codigoProduto
    this.codigoProduto = codigoInput.value.trim();

    // Chame o serviço para buscar os produtos com base na filial e no código do produto
    this.estoqueService.buscarPrecoProdutos(this.codigoProduto).subscribe(
      (response: any) => {
        this.loading = true;
        // Verificar se a resposta contém a chave esperada
        if (!response || !response.precoProduto) {
          this.loading = false;
          console.error('Resposta da API inválida:', response);
          return;
        }

        const { precoProduto } = response;

        // Verificar se a resposta contém produtos
        if (!Array.isArray(precoProduto) || precoProduto.length === 0) {
          this.loading = false;
          console.log('Nenhum produto encontrado');
          this.produtoNaoEncontrado();
          this.produtos = [];
          return;
        }

        // Produtos encontrados, continue como antes
        this.produtos = precoProduto;
        this.coresDisponiveis = this.extrairCoresDisponiveis(this.produtos);
        this.tamanhosDisponiveis = this.extrairTamanhosDisponiveis(this.produtos);
        this.onOpenModalClick();
        this.loading = false;
      },
      (error) => {
        console.error('Erro ao buscar produtos:', error);
      }
    );
  }

  async confirmarExclusao(): Promise<void> {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonColor: 'rgb(220, 53, 69)',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      Swal.fire(
        'Deleted!',
        'User has been deleted.',
        'success'
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        '',
        'error'
      );
    }
  }

  isNumero(value: any): boolean {
    return !isNaN(value) && typeof value === 'number';
  }

  onOpenModalClick(): void {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  }

  onCloseModalClick(): void {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
  }

  filtrarProdutos(): PrecoProduto[] {
    if (!this.selectedCor && !this.selectedTamanho) {
      return this.produtos;
    }

    const produtosFiltrados = this.produtos.filter(produto => {
      this.loading = true;
      // Verificar se a cor do produto corresponde à cor selecionada
      const corCorrespondente = !this.selectedCor || produto.COR_PRODUTO === this.selectedCor;

      // Verificar se o tamanho selecionado está presente nos tamanhos do produto
      const tamanhoCorrespondente = !this.selectedTamanho || produto.TAMANHO.toString() === this.selectedTamanho.trim();

      this.loading = false;

      return corCorrespondente && tamanhoCorrespondente;
    });
    return produtosFiltrados;
  }






  // Método para extrair cores disponíveis dos produtos filtrados
  extrairCoresDisponiveis(produtosFiltrados: PrecoProduto[]): string[] {
    const coresDisponiveis: string[] = [];

    // Iterar sobre os produtos filtrados
    produtosFiltrados.forEach(produto => {
      // Remover espaços em branco extras e adicionar a cor à lista de cores disponíveis, se ainda não estiver presente
      const corLimpa = produto.COR_PRODUTO.trim();
      if (!coresDisponiveis.includes(corLimpa)) {
        coresDisponiveis.push(corLimpa);
      }
    });

    return coresDisponiveis;
  }


  // Método para extrair tamanhos disponíveis dos produtos filtrados
  extrairTamanhosDisponiveis(produtos: any[]): Set<string> {
    const tamanhos: Set<string> = new Set();

    produtos.forEach((produto) => {
      if (produto && produto.TAMANHO) {
        tamanhos.add(produto.TAMANHO.toString().trim());
      }
    });

    return tamanhos;
  }





}
