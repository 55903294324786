import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseConnectionService {
  constructor(private firestore: AngularFirestore) { }

  checkConnection(): Observable<boolean> {
    return this.firestore.collection('test').valueChanges().pipe(
      map((docs) => {
        console.log('Firestore documents:', docs);
        return true;
      }),
      catchError((error) => {
        console.error('Firestore connection error:', error);
        return of(false);
      })
    );
  }
}
