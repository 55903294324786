import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class MercadoPagoService {
  private API_URL = 'https://api.mercadopago.com/v1/payments/search';
  // private accessToken = 'TEST-6702165567350453-062817-5b026fe121a1d4b9e569600cc0e242fc-255374454'; // Coloque seu token aqui
  // private accessToken = 'APP_USR-5052253783798702-080214-ddd8eb234a1e69bc638e95f61947d890-146511220'; // Coloque seu token aqui

  constructor(private http: HttpClient) { }

  getPayments(limit: number = 30, offset: number = 0): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${environment.accessToken}`
    });
    const params = new HttpParams()
      .set('sort', 'date_created')
      .set('criteria', 'desc')
      .set('range', 'date_created')
      .set('begin_date', 'NOW-30DAYS')
      .set('end_date', 'NOW')
      .set('limit', limit.toString())
      .set('offset', offset.toString());

    return this.http.get(this.API_URL, { headers, params });
  }
}
