import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-flags',
  templateUrl: './icon-flags.component.html',
  styleUrls: ['./icon-flags.component.scss']
})
export class IconFlagsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
