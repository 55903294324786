import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../core/services/login.service';
import { SharedService } from '../../../core/services/pdv/SharedService';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isFullScreen: boolean;
  contactTab: boolean;
  groupTab: boolean;
  chatTab = true;
  title: any;
  Ntitle: any;
  notification: string;
  notificationAlert = false;
  notificationUnRead = 'true';
  notifications: any[] = [];

  constructor(
    private route: Router,
    private loginService: LoginService,
    public sharedService: SharedService,
    private firestore: AngularFirestore
  ) {
    this.title = route.url;
    this.title = this.title.replace(/\//g, '').toUpperCase();
  }

  ngOnInit(): void {
    this.Ntitle = this.title.replace(/^APPPORTAL-/g, '');
    this.notificationUnRead = sessionStorage.getItem('notificationUnRead') || 'false';

    this.fetchNotifications();

    if (this.sharedService.notificacao && this.sharedService.notificacao.trim() !== '') {
      this.notificationAlert = true;
      this.notification = this.sharedService.notificacao;
    } else {
      this.notificationAlert = false;
    }
  }

  fetchNotifications() {
    this.firestore.collection('notifications', ref =>
      ref.orderBy('timestamp', 'desc').limit(5)
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        let title = '';
        let message = '';

        if (data.message.startsWith('Novo pedido criado:')) {
          title = 'Novo Pedido';
          message = `Pedido: ${data.orderId}`; // Utiliza a orderId diretamente
        } else {
          title = data.title;
          message = data.message;
        }

        return { id, title, message, read: data.read, timestamp: data.timestamp }; // Exclui a mensagem original do retorno
      }))
    ).subscribe(data => {
      // Prioriza notificações não lidas
      const unread = data.filter(n => !n.read);
      const read = data.filter(n => n.read);
      this.notifications = [...unread, ...read].slice(0, 10); // Garante que são 10 notificações no máximo
      this.notificationUnRead = this.notifications.some(n => !n.read) ? 'true' : 'false';
      // console.log('Notifications:', this.notifications);
    });
  }

  mToggoleMenu() {
    document.getElementsByTagName('body')[0].classList.toggle('offcanvas-active');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');
  }

  noteToggle() {
    document.getElementsByClassName('sticky-note')[0].classList.toggle('open');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');
  }

  openRightMenu() {
    document.getElementById('rightbar').classList.toggle('open');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');
  }

  openFullScreen() {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((elem as any).mozRequestFullScreen) {
      (elem as any).mozRequestFullScreen();
    } else if ((elem as any).webkitRequestFullscreen) {
      (elem as any).webkitRequestFullscreen();
    } else if ((elem as any).msRequestFullscreen) {
      (elem as any).msRequestFullscreen();
    }
    this.isFullScreen = true;
  }

  closeFullScreen() {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen?: () => Promise<void>;
      webkitExitFullscreen?: () => Promise<void>;
      msExitFullscreen?: () => Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) {
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) {
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
    this.isFullScreen = false;
  }

  onTab(number) {
    this.chatTab = false;
    this.groupTab = false;
    this.contactTab = false;
    if (number === '1') {
      this.chatTab = true;
    } else if (number === '2') {
      this.groupTab = true;
    } else if (number === '3') {
      this.contactTab = true;
    }
  }

  logout() {
    this.loginService.logout();
  }

  markNotificationAsRead(notificationId: string) {
    this.firestore.collection('notifications').doc(notificationId).update({
      read: true
    }).then(() => {
      // console.log(`Notificação ${notificationId} marcada como lida.`);
      this.fetchNotifications(); // Re-fetch notifications after marking as read
    }).catch(error => {
      console.error('Erro ao marcar notificação como lida: ', error);
    });
  }

  markAllAsRead() {
    this.notifications.forEach(notification => {
      this.firestore.collection('notifications').doc(notification.id).update({ read: true });
    });
    this.fetchNotifications();
  }
}
