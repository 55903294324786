import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EstoqueProduto} from '../../_models/estoque';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstoqueService {

  private baseUrl = `${environment.apiUrl}/estoque`;

  constructor(private http: HttpClient) { }

  // Método para buscar produtos por filial e código do produto
  buscarPorFilialEProduto(filial: string, codigoProduto: string): Observable<EstoqueProduto[]> {
    return this.http.get<EstoqueProduto[]>(`${this.baseUrl}/estoqueConsult?filial=${filial}&produto=${codigoProduto}`);
  }

  // Método para buscar preço dos produtos
  buscarPrecoProdutos(refProduto: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/priceProduct?refProduto=${refProduto}`);
  }


}
