import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientRoutingModule } from './client-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { LeftmenuComponent } from './leftmenu/leftmenu.component';
import { ChatComponent } from './chat/chat.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { FormElementComponent } from './form-element/form-element.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSliderModule } from '@angular/material/slider';
import { TablesComponent } from './UI-ELEMENTS/tables/tables.component';
import { ChartsComponent } from './charts/charts.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';
import { ColumnChartComponent } from './charts/column-chart/column-chart.component';
import { AreaChartComponent } from './charts/area-chart/area-chart.component';
import { RadarChartComponent } from './charts/radar-chart/radar-chart.component';
import { RadialbarChartComponent } from './charts/radialbar-chart/radialbar-chart.component';
import { HeatmapChartComponent } from './charts/heatmap-chart/heatmap-chart.component';
import { CandlestickChartComponent } from './charts/candlestick-chart/candlestick-chart.component';
import { WidgetsComponent } from './widgets/widgets.component';
import { CalenderComponent } from './calender/calender.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ContactsComponent } from './contacts/contacts.component';
import { FilemanagerComponent } from './filemanager/filemanager.component';
import { SparklinesComponent } from './charts/sparklines/sparklines.component';
import { SettingsComponent } from './settings/settings.component';
import { MinbarchartComponent } from './charts/minbarchart/minbarchart.component';
import { FullcalenderComponent } from './fullcalender/fullcalender.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import { WorldmapComponent } from './worldmap/worldmap.component';
import { AgmCoreModule } from '@agm/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CkeditorComponent } from './ckeditor/ckeditor.component';
import { MapsComponent } from './maps/maps.component';
import { SearchComponent } from './search/search.component';
import { ProjectComponent } from './project/project.component';
import { PrDashboardComponent } from './project/pr-dashboard/pr-dashboard.component';
import { PrProjectlistComponent } from './project/pr-projectlist/pr-projectlist.component';
import { PrTaskboardComponent } from './project/pr-taskboard/pr-taskboard.component';
import { PrTicketdetailsComponent } from './project/pr-ticketdetails/pr-ticketdetails.component';
import { PrTicketlistComponent } from './project/pr-ticketlist/pr-ticketlist.component';
import { PrClientsComponent } from './project/pr-clients/pr-clients.component';
import { PrTodolistComponent } from './project/pr-todolist/pr-todolist.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { UIELEMENTSComponent } from './ui-elements/ui-elements.component';
import { IconsComponent } from './UI-ELEMENTS/icons/icons.component';
import { IconFontawesomeComponent } from './UI-ELEMENTS/icons/icon-fontawesome/icon-fontawesome.component';
import { IconFeatherComponent } from './UI-ELEMENTS/icons/icon-feather/icon-feather.component';
import { IconLinesComponent } from './UI-ELEMENTS/icons/icon-lines/icon-lines.component';
import { IconFlagsComponent } from './UI-ELEMENTS/icons/icon-flags/icon-flags.component';
import { IconPaymentsComponent } from './UI-ELEMENTS/icons/icon-payments/icon-payments.component';
import { GalleryComponent } from './ui-elements/gallery/gallery.component';
import { BasicradarChartComponent } from './charts/basicradar-chart/basicradar-chart.component';
import { ColumnLineChartComponent } from './charts/column-line-chart/column-line-chart.component';
import { WCardComponent } from './widgets/w-card/w-card.component';
import { WStaticsComponent } from './widgets/w-statics/w-statics.component';
import { WDataComponent } from './widgets/w-data/w-data.component';
import { WSocialComponent } from './widgets/w-social/w-social.component';
import { WOtherComponent } from './widgets/w-other/w-other.component';
import { ProfileComponent } from './profile/profile.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CountToModule } from 'angular-count-to';
import { FooterComponent } from './footer/footer.component';
import {PdvComponent} from './pdv/pdv.component';
import {ErpReportComponent} from './pdv/pdv-report/erp-report.component';
import {PdvEstoqueComponent, FilterByFirstLetterPipe} from './pdv/pdv-estoque/pdv-estoque.component';
import {PdvDashboardComponent} from './pdv/pdv-dashboard/pdv-dashboard.component';
import {ErpDepartmentsComponent} from './pdv/pdv-departments/erp-departments.component';
import {ErpEmployeeComponent} from './pdv/pdv-employee/erp-employee.component';
import {ErpActivitiesComponent} from './pdv/pdv-activities/erp-activities.component';
import {ErpHolidaysComponent} from './pdv/pdv-holidays/erp-holidays.component';
import {ErpEventsComponent} from './pdv/pdv-events/erp-events.component';
import {ErpPayrollComponent} from './pdv/pdv-payroll/erp-payroll.component';
import {ErpAccountsComponent} from './pdv/pdv-accounts/erp-accounts.component';
import {PdvClientsComponent} from './pdv/pdv-clients/pdv-clients.component';
import {TextMaskModule} from 'angular2-text-mask';
import {PdvPrecoComponent} from './pdv/pdv-preco/pdv-preco.component';
import {PdvPedidosComponent} from './pdv/pdv-pedidos/pdv-pedidos.component';
import {AddClientComponent} from './pdv/pdv-clients/_modals/add/addClient.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FilterPipeC, SearchClientComponent} from './pdv/pdv-clients/_modals/search/searchClient.component';
import {ProdutoModalComponent} from './pdv/pdv-estoque/_modals/product/produtoModal.component';
import {AppPortalReportComponent, TimestampToDateHourPipe} from './appPortal/appPortal-report/appPortal-report.component';
import {AppPortalComponent} from './appPortal/appPortal.component';
import {AppPortalPrecoComponent, CommaToDotPipe} from './appPortal/appPortal-preco/appPortal-preco.component';
import {AppPortalPedidosComponent} from './appPortal/appPortal-pedidos/appPortal-pedidos.component';
import {AppPortalPayrollComponent} from './appPortal/appPortal-payroll/appPortal-payroll.component';
import {AppPortalEstoqueComponent, FilterByProdutos} from './appPortal/appPortal-estoque/appPortal-estoque.component';
import {AppPortalDashboardComponent} from './appPortal/appPortal-dashboard/appPortal-dashboard.component';
import {AppPortalClientsComponent, TimestampToDatePipe, TitleCasePipe} from './appPortal/appPortal-clients/appPortal-clients.component';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
    imports: [
        CommonModule,
        ClientRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        CollapseModule.forRoot(),
        AccordionModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        AlertModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ProgressbarModule.forRoot(),
        CarouselModule.forRoot(),
        DragDropModule,
        MatSliderModule,
        NgApexchartsModule,
        NgMultiSelectDropDownModule.forRoot(),
        CountToModule,
        ToastrModule.forRoot({}),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        AgmCoreModule.forRoot({
            apiKey: 'GOOGLE_API_KEY'
        }),
        FullCalendarModule,
        CKEditorModule,
        TextMaskModule,
        MatDialogModule
    ],
  declarations: [
    ClientRoutingModule.components,
    HeaderComponent,
    LeftmenuComponent,
    ChatComponent,
    FormElementComponent,
    TablesComponent,
    ChartsComponent,
    LineChartComponent,
    BarChartComponent,
    PieChartComponent,
    ColumnChartComponent,
    AreaChartComponent,
    RadarChartComponent,
    RadialbarChartComponent,
    HeatmapChartComponent,
    CandlestickChartComponent,
    WidgetsComponent,
    CalenderComponent,
    ContactsComponent,
    FilemanagerComponent,
    SparklinesComponent,
    SettingsComponent,
    MinbarchartComponent,
    FullcalenderComponent,
    WorldmapComponent,
    CkeditorComponent,
    MapsComponent,
    SearchComponent,
    ProjectComponent,
    PrDashboardComponent,
    PrProjectlistComponent,
    PrTaskboardComponent,
    PrTicketdetailsComponent,
    PrTicketlistComponent,
    PrClientsComponent,
    PrTodolistComponent,
    PdvComponent,
    PdvEstoqueComponent,
    PdvPedidosComponent,
    AddClientComponent,
    SearchClientComponent,
    ProdutoModalComponent,
    PdvPrecoComponent,
    PdvClientsComponent,
    PdvDashboardComponent,
    AppPortalComponent,
    AppPortalReportComponent,
    AppPortalPrecoComponent,
    AppPortalPedidosComponent,
    AppPortalPayrollComponent,
    AppPortalEstoqueComponent,
    AppPortalDashboardComponent,
    AppPortalClientsComponent,
    ErpDepartmentsComponent,
    ErpEmployeeComponent,
    ErpActivitiesComponent,
    ErpHolidaysComponent,
    ErpEventsComponent,
    ErpPayrollComponent,
    ErpAccountsComponent,
    ErpReportComponent,
    UIELEMENTSComponent,
    IconsComponent,
    IconFontawesomeComponent,
    IconFeatherComponent,
    IconLinesComponent,
    IconFlagsComponent,
    IconPaymentsComponent,
    GalleryComponent,
    BasicradarChartComponent,
    ColumnLineChartComponent,
    WCardComponent,
    WStaticsComponent,
    WDataComponent,
    WSocialComponent,
    WOtherComponent,
    ProfileComponent,
    FooterComponent,
    FilterByFirstLetterPipe,
    FilterByProdutos,
    FilterPipeC,
    TimestampToDatePipe,
    CommaToDotPipe,
    TitleCasePipe,
    TimestampToDateHourPipe,
  ],
  providers: [BsDatepickerModule],

  exports: [
    FilterByFirstLetterPipe,
    FilterByProdutos,
    TimestampToDatePipe,
    CommaToDotPipe,
    TitleCasePipe,
    TimestampToDateHourPipe,
  ]
})
export class ClientModule { }
