import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from './user.service';
import { LoginService } from './login.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserTypeGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private loginService: LoginService,
    private afAuth: AngularFireAuth
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.afAuth.authState.pipe(
      take(1),
      map(user => {
        const isLoggedIn = !!user || this.loginService.userValue;

        if (!isLoggedIn) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          return false;
        }

        const userType = this.userService.getUserType();
        if (userType === 'appPortal-dashboard' || userType === 'pdv-dashboard') {
          return true;
        } else {
          this.router.navigate(['/error-404']);
          return false;
        }
      })
    );
  }
}
